import React from 'react';

import AdditionalHomeDetails from '@client/components/AdditionalHomeDetails';
import ConditionalFeature from '@client/components/ConditionalFeature';
import FindAgentBanner from '@client/components/FindAgent/FindAgentBanner';
import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import GrantProgramPDPBanner from '@client/components/GrantProgram/GrantProgramPDPBanner';
import MLSAttributionPDPNWMLSException from '@client/components/MLSAttributionPDPNWMLSException';
import OpenHouseInfo from '@client/components/OpenHouseInfo';
import PDPRightRailCardCobranded from '@client/components/PDPRightRailCard/PDPRightRailCardCobranded';
import AvmBreakdownContainer from '@client/containers/avm-breakdown.container';
import BreakoutSectionsContainer from '@client/containers/breakout-sections.container';
import BrokerageAttributionContainer from '@client/containers/brokerage-attribution.container';
import CarouselRowContainer from '@client/containers/carousel-row.container';
import Footer from '@client/containers/footer.container';
import HomeownerUpsellAdContainer from '@client/containers/homeowner-upsell-ad.container';
import ListingInfoContainer from '@client/containers/listing-info.container';
import MapPropertyPageContainer from '@client/containers/map-property-page.container';
import NearbyListingsContainer from '@client/containers/nearby-listings.container';
import PropertyIntroContainer from '@client/containers/property-intro.container';
import theme from '@client/css-modules/PropertyPageDesktopContents.css';
import { getPropertyDetailPageConfig } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { PropertyPageDesktopContentsProps } from '@client/store/types/cobranded-components/property-page';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import CustomPropertyBanner from '../CustomPropertyBanner';

/**
 * Contains the contents displayed within the PDP on desktop.
 */
const PropertyPageDesktopContents: React.FC<
  PropertyPageDesktopContentsProps
> = ({
  isActiveListing,
  shouldShowHomeownerAd,
  isCanaryUIFeatureEnabled,
  isHidingAvmBreakdown,
}) => {
  const propertyPageData = useSelector(getPropertyDetailPageConfig);
  const showCustomBanner =
    propertyPageData.bannerHeadline &&
    propertyPageData.bannerButtonLabel &&
    propertyPageData.bannerDestinationUrl;
  const isAlternateComehomeBrandingAndBrokerageDesignEnabled = useSelector(
    getIsFeatureEnabled('alternate_comehome_branding_and_brokerage_design')
  );

  return (
    <>
      <CarouselRowContainer />
      <div className={theme.Columns}>
        <div
          className={classNames(theme.LeftSide, {
            [theme.AlternateDesignLeftSide]:
              isAlternateComehomeBrandingAndBrokerageDesignEnabled,
          })}
        >
          <MLSAttributionPDPNWMLSException />
          {!isAlternateComehomeBrandingAndBrokerageDesignEnabled && (
            <div className={theme.AttributionWrapper}>
              <BrokerageAttributionContainer
                isMinimalStyling={false}
                theme={theme}
              />
            </div>
          )}
          <PropertyIntroContainer addressAsHeader theme={theme} />
          <OpenHouseInfo />
          <ListingInfoContainer theme={theme} />
          {isAlternateComehomeBrandingAndBrokerageDesignEnabled && (
            <BrokerageAttributionContainer
              isMinimalStyling={false}
              theme={theme}
            />
          )}
          <AdditionalHomeDetails />
          <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
            <GrantProgramPDPBanner />
          </ConditionalFeature>
          {
            /* The Homeowner ad is placed differently on the page for onsale/offsale properties */
            !isActiveListing && shouldShowHomeownerAd && (
              <HomeownerUpsellAdContainer />
            )
          }
          <ConditionalFeature
            renderIfFeaturesEnabled={['referral_services', 'your_team']}
          >
            <div
              className={theme.FindAgentWrapper}
              data-hc-name="find-agent-section"
            >
              <FindAgentBanner isNarrowLayout={false} />
            </div>
          </ConditionalFeature>
          {showCustomBanner && (
            <div
              className={theme.FindAgentWrapper}
              data-hc-name="find-agent-section"
            >
              <CustomPropertyBanner
                isNarrowLayout={false}
                propertyPageData={propertyPageData}
              />
            </div>
          )}
          <MapPropertyPageContainer />
          <HorizontalSeparator theme={theme} />
          {!isHidingAvmBreakdown && <AvmBreakdownContainer />}
          <BreakoutSectionsContainer />
          {
            /* The Homeowner ad is placed differently on the page for onsale/offsale properties */
            isActiveListing && shouldShowHomeownerAd && (
              <HomeownerUpsellAdContainer />
            )
          }
          <NearbyListingsContainer />
        </div>
        <div className={theme.RightSide} data-hc-name="right-side-column">
          <PDPRightRailCardCobranded
            theme={theme}
            isShowingMonthlyPaymentDetails
            isShowingShareAndSaveSection
            isStandaloneModule
            isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
          />
        </div>
      </div>
      <HorizontalSeparator theme={theme} />
      <Footer theme={theme} shouldUseSectionElement />
    </>
  );
};

export default PropertyPageDesktopContents;
