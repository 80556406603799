import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { LngLatBounds } from 'mapbox-gl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import MapMarkerPopup from '@client/components/MapMarkerPopup';
import { HandleMapPositionChangeArgs } from '@client/components/SearchPageMap';
import SearchPageMapWrapper from '@client/components/SearchPageMapWrapper';
import { ALL_SEARCH_VIEWS, View } from '@client/routes/constants';
import {
  reportClickSearchMapAutoCompleteSearchButton,
  reportEvent,
  reportPropertyCardClick,
  reportSearchClickCurrentLocation,
} from '@client/store/actions/analytics.actions';
import { fetchMapLegendBreaks } from '@client/store/actions/map-legend-breaks.actions';
import { setLocalStoragePropertySeen } from '@client/store/actions/property-details.actions';
import { clearCurrentSavedSearch } from '@client/store/actions/saved-search.actions';
import {
  searchAddPropertyListPropertyToPropertyDataCache,
  searchCancelPendingQueries,
  searchClearConstrainedToPlace,
  searchClearResultMapMarkers,
  searchFetchMapPropertiesForBounds,
  searchFetchMoreSidebarProperties,
  searchFetchPropertyList,
  searchFetchSelectedMapProperty,
  searchFieldClick,
  searchGetUserLocationViaSelection,
  searchHighlightSchoolMarkers,
  searchListApplySort,
  searchMapMovedAfterInit,
  searchPropertyCardHover,
  searchRemoveTemporaryMarker,
  searchReportShowingMapBottomLayerControl,
  searchReportUpdateTextBox,
  searchRouteChangeToHidePDPModal,
  searchSetActiveMultiUnitProperties,
  searchSetMapViewport,
  searchSetMLSLayerFeatures,
  searchSetMultiUnitMarkerData,
  searchSetPDPModalMarkerDataAndShowModal,
  searchToggleMapBottomLayerGroupsList,
  searchToggleMarkers,
  searchUpdateTextBox,
  searchUpdateUrlParams,
} from '@client/store/actions/search.actions';
import { fetchWatchListIfNotInState } from '@client/store/actions/watchlist.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { STATUSES } from '@client/store/constants';
import {
  FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS,
  FIT_POPUP_PADDING_WITH_MOBILE_CONTROLS,
  LayerMetric,
} from '@client/store/map-constants';
import {
  getAccessToken,
  getIsLoggedIn,
} from '@client/store/selectors/auth.selectors';
import {
  getIsFeatureEnabled,
  getSearchListMaxResultCount,
} from '@client/store/selectors/enabled-features.selectors';
import {
  getIsFourHundredZoom,
  getIsSmallSize,
  getIsTabletOrSmallerSize,
  getIsTouchDevice,
} from '@client/store/selectors/match-media.selectors';
import {
  getHasSavedCurrentSearch,
  getSavedSearchSavingStatus,
} from '@client/store/selectors/saved-search.selectors';
import {
  getConstrainedToPlaceHasLimitedMLSCoverage,
  getFinalSearchActiveFilterCount,
  getIsShowingMapMarkers,
  getIsShowingNoMLSModal,
  getIsShowingSearchPageList,
  getIsUsingMobileFiltersUI,
  getSearchActiveMultiUnitProperties,
  getSearchAreDefaultFiltersChanged,
  getSearchConstrainedPlace,
  getSearchFilterSetIncludesOffMarketProperties,
  getSearchIncompleteMLSCoverageExistsInMapArea,
  getSearchInvalidateMapSizeTrigger,
  getSearchIsLoadingMoreListProperties,
  getSearchIsMobileMapPropertyCardActive,
  getSearchIsShowingMapBottomLayerGroupsList,
  getSearchIsShowingMLSCoverageLayer,
  getSearchIsShowingMobileFilters,
  getSearchListCursor,
  getSearchListLastUpdatedTimestamp,
  getSearchListProperties,
  getSearchListPropertiesWithAdCard,
  getSearchListSortField,
  getSearchListSortOrder,
  getSearchMarkerFeaturesMinZoom,
  getSearchMarkerLoadedTilesChangedTrigger,
  getSearchMarkersAreAllClusters,
  getSearchMarkersGeoJSONFeatures,
  getSearchMoreListPropertiesAvailable,
  getSearchMultiUnitMarkersByLatLng,
  getSearchPlaceGeoJSON,
  getSearchPlaceGeoJSONDescription,
  getSearchPropertyListIsErrorStatus,
  getSearchPropertyListIsSuccessStatus,
  getSearchPropertyTotalCount,
  getSearchPulseLocations,
  getSearchSetMapLocation,
  getSearchShouldShowLoadingIndicatorWhenLoading,
  getSearchShowMobileMapDisclaimerFairHousingNY,
  getSearchShowPropertyListDisclaimerFairHousingNY,
  getSearchTemporaryMarker,
} from '@client/store/selectors/search.selectors';
import { getScaffoldingCMGMarketingCardDataForSRP } from '@client/store/slices/scaffolding-data.slice';
import {
  GaiaSchoolsById,
  MLSCoverageLayerFeatureProperties,
  MultiUnitDataObject,
  PropertyMapMarker,
} from '@client/store/types/maps';
import { NormalizedProperty } from '@client/store/types/property';
import {
  SearchListSortField,
  SearchListSortOrder,
} from '@client/store/types/search';
import {
  getIsPositionBounds,
  padBoundsByPercentage,
} from '@client/utils/maps.utils';
import { routeChange } from '@src/redux-saga-router-plus/actions';

const EMPTY = [];
const MapMarkerPopupElement = <MapMarkerPopup />;

const mapStateToProps = (state) => {
  const mapLocation = getSearchSetMapLocation(state);
  const isSmallScreen = getIsSmallSize(state);
  const hasSavedCurrentSearch = getHasSavedCurrentSearch(state);
  const isShowingMarkers = getIsShowingMapMarkers(state);
  const constrainedToPlace = getSearchConstrainedPlace(state);
  const isPDPModalActive =
    isSmallScreen && getSearchIsMobileMapPropertyCardActive(state);
  const currentView = getCurrentView(state);
  const propertyList = getSearchListProperties(state);
  const isShowingSearchList = getIsShowingSearchPageList(state);

  /* Please keep in alpha order */
  return {
    /* Grant toggle exists outside of filters but is within the same UI */
    activeFilterCount: getFinalSearchActiveFilterCount(state),
    activeMultiUnitProperties: getSearchActiveMultiUnitProperties(state),
    allMarkerAreClusters: getSearchMarkersAreAllClusters(state),
    allowShowingMobileButtons: getIsUsingMobileFiltersUI(state),
    areDefaultFiltersChanged: getSearchAreDefaultFiltersChanged(state),
    canLoadMoreSidebarProperties: getSearchMoreListPropertiesAvailable(state),
    /* Disabling until we decide to support mobile devices and improve dragging behavior on tablets */
    disableSidebarDragging: getIsTouchDevice(state),
    fitPopupPadding: isSmallScreen
      ? FIT_POPUP_PADDING_WITH_MOBILE_CONTROLS
      : FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS,
    hasSavedCurrentSearch: hasSavedCurrentSearch,
    hasSeparateListPage: isSmallScreen,
    invalidateMapSizeTrigger: getSearchInvalidateMapSizeTrigger(state),
    isFourHundredPercentZoom: getIsFourHundredZoom(state),
    isConstrainedToPlaceWithLimitedCoverage:
      getConstrainedToPlaceHasLimitedMLSCoverage(state),
    isLoadingMoreSidebarProperties: getSearchIsLoadingMoreListProperties(state),
    isPropertyListInitOrLoadingStatus:
      !getSearchPropertyListIsSuccessStatus(state),
    isPropertyListErrorStatus: getSearchPropertyListIsErrorStatus(state),
    shouldShowListLoadingIndicatorWhenLoading:
      getSearchShouldShowLoadingIndicatorWhenLoading(state),
    isLoggedIn: getIsLoggedIn(state),
    isPDPModalActive: isPDPModalActive,
    isScaffoldingCMGMarketingCardDataForSRP:
      !!getScaffoldingCMGMarketingCardDataForSRP(state),
    isSavingCurrentSearch:
      getSavedSearchSavingStatus(state) === STATUSES.UPDATING,
    isShowingMapBottomLayerGroupsList:
      getSearchIsShowingMapBottomLayerGroupsList(state),
    isShowingMapZoomControls: !isSmallScreen,
    isShowingMLSCoverageLayer: getSearchIsShowingMLSCoverageLayer(state),
    isMLSCoverageIncompleteForMapArea:
      getSearchIncompleteMLSCoverageExistsInMapArea(state),
    isSmallScreen,
    isShowingMarkers,
    isShowingMobileFilters: getSearchIsShowingMobileFilters(state),
    isShowingNoMLSModal: getIsShowingNoMLSModal(state),
    isShowingResultCount: !isSmallScreen,
    isShowingSearchInput: !isSmallScreen,
    isShowingSearchList,
    isShowingSidebarForScreenSize: !isSmallScreen,
    isShowingTooLowZoomWarningOverMap: isSmallScreen,
    isSortControlDisabled:
      getSearchFilterSetIncludesOffMarketProperties(state) ||
      propertyList.length === 0,
    isUsingLegacySaveSearchButton: getIsFeatureEnabled(
      'legacy_save_search_button'
    )(state),
    isCanaryUIFeatureEnabled: getIsFeatureEnabled('canary_ui')(state),
    isCtaCleanupEnabled: getIsFeatureEnabled('cta_cleanup')(state),
    isYourTeamEnabled: getIsFeatureEnabled('your_team')(state),
    isAlternateComehomeBrandingAndBrokerageDesignEnabled: getIsFeatureEnabled(
      'alternate_comehome_branding_and_brokerage_design'
    )(state),
    mapGeoRequestAccessToken: getAccessToken(state),
    mapPulseLocations: getSearchPulseLocations(state),
    markerFeatures: isShowingMarkers
      ? getSearchMarkersGeoJSONFeatures(state)
      : EMPTY,
    markerFeaturesChangedTrigger:
      getSearchMarkerLoadedTilesChangedTrigger(state),
    markerFeaturesMinZoom: getSearchMarkerFeaturesMinZoom(state),
    MarkerPopup: !isSmallScreen ? MapMarkerPopupElement : undefined,
    maxResultCount: getSearchListMaxResultCount(state),
    multiUnitMarkersByLatLng: getSearchMultiUnitMarkersByLatLng(state),
    placeBoundaryGeoJSON: getSearchPlaceGeoJSON(state),
    placeBoundaryUpdateTrigger: constrainedToPlace
      ? constrainedToPlace.placeId
      : null,
    placeGeoJSONDescription: getSearchPlaceGeoJSONDescription(state),
    propertyListLastUpdatedTimestamp: getSearchListLastUpdatedTimestamp(state),
    setMapLocation: mapLocation,
    shouldAllowHandlingMapPositionChange: !!(
      currentView && ALL_SEARCH_VIEWS.indexOf(currentView) > -1
    ),
    shouldCenterMapMarkerOnClick: isSmallScreen,
    shouldClearConstrainedPlaceOnClearingSearch: !!constrainedToPlace,
    shouldDisplayCurrentLocationSearchOption: isSmallScreen,
    shouldGetGeolocationFromDevice: getIsTouchDevice(state),
    shouldHideSidebarForConstrainedPlaceWithNoResults: getIsFeatureEnabled(
      'hide_search_sidebar_constrained_place_no_results'
    )(state),
    shouldMaintainUrlWhenUpdatingPlaceBoundary: currentView === View.CITIES,
    /* Don't render the map unless we have the user's location. The location is either set
     * server-side if cookie'd or in the URL, in the cities/zipcode route, or in the search
     * route if no location is specified and is needed from the user's device */
    shouldRenderMap: !!mapLocation,
    shouldShowPDPModalOnPropertySelect: isSmallScreen,
    sidebarProperties: propertyList,
    sidebarPropertiesWithAdCard: getSearchListPropertiesWithAdCard(state),
    sidebarPropertiesCursor: getSearchListCursor(state),
    shouldShowSidebarDisclaimerFairHousingNY:
      getSearchShowPropertyListDisclaimerFairHousingNY(state),
    shouldShowMobileDisclaimerFairHousingNY:
      getSearchShowMobileMapDisclaimerFairHousingNY(state),
    sortField: getSearchListSortField(state),
    sortOrder: getSearchListSortOrder(state),
    temporaryMarker: getSearchTemporaryMarker(state),
    totalPropertiesAvailable: getSearchPropertyTotalCount(state),
    useConstrainedToPlaceNoResultsSidebarMessage: !!constrainedToPlace,
    useFloatingMapLayerGroupsControl: !isSmallScreen,
    /* This is used when setting initial map location based on user's location */
    zoomWhenPositioningToPoint: getIsPositionBounds(mapLocation)
      ? undefined
      : 13,
    /* On tablet and smaller size screens, the brokerage attribution appears in the header, and is not needed in the sidebar: */
    isShowingBrokerageAttribution: !getIsTabletOrSmallerSize(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleFetchSelectedPropertySidebarData: (slug: string): void => {
    dispatch(searchFetchSelectedMapProperty(slug));
  },
  handleReportMarkerClick: (slug: string): void => {
    dispatch(setLocalStoragePropertySeen(slug));
    dispatch(reportEvent('click_search_map_marker'));
  },
  handleClearCurrentSavedSearch: (): void => {
    dispatch(clearCurrentSavedSearch());
  },
  /* Load properties for the sidebar, update cached map position and URL params */
  handleMapPositionChange: ({
    bounds,
    zoom,
    allowUpdatingUrlParams,
    shouldReloadSidebarProperties,
    shouldResetPropertyListState,
    showSidebarLoadingIndicator,
  }: HandleMapPositionChangeArgs): void => {
    /* Pad slightly, so we're only retrieving properties in the inner 98% of the map area */
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    const paddedBounds = padBoundsByPercentage({ southWest, northEast }, -0.02);
    /* Store current map position in state */
    dispatch(
      searchSetMapViewport({
        southWest,
        northEast,
        zoom,
        /* This is only true if we're viewing only the map on a small screen */
        shouldResetPropertyListState,
      })
    );
    if (shouldReloadSidebarProperties) {
      /* Load new set of properties in sidebar */
      /* Pass lat/lng directly to saga for API request to avoid potential race condition */
      dispatch(
        searchFetchPropertyList({
          bounds: paddedBounds,
          showLoadingIndicator: showSidebarLoadingIndicator,
        })
      );
    }
    /* Update route params */
    if (allowUpdatingUrlParams) {
      dispatch(searchUpdateUrlParams({ shouldReplace: true }));
    }
  },
  handleMapMoveAfterInitialized: (bounds: LngLatBounds): void => {
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    dispatch(searchMapMovedAfterInit({ southWest, northEast }));
  },
  /* Break the viewport apart into tiles and load properties for tiles */
  handleFetchMapProperties: ({
    bounds,
    zoom,
  }: {
    bounds: LngLatBounds;
    zoom: number;
  }): void => {
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    dispatch(searchFetchMapPropertiesForBounds({ southWest, northEast, zoom }));
  },
  handleCancelPendingPropertyQueries: (): void => {
    dispatch(searchCancelPendingQueries());
  },
  handleRouteChange: (view: View, queryParams?: {}) =>
    dispatch(routeChange({ view: view, query: queryParams })),
  handleToggleMarkers: (
    shouldShow: boolean,
    bounds: LngLatBounds,
    zoom: number
  ): void => {
    dispatch(searchToggleMarkers(shouldShow));
    if (shouldShow) {
      const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();
      /* Break the viewport apart into tiles and load properties for tiles */
      dispatch(
        searchFetchMapPropertiesForBounds({ southWest, northEast, zoom })
      );
    } else {
      dispatch(searchClearResultMapMarkers());
    }
  },
  handleAPIClusterMarkerClick: (): void => {
    dispatch(searchClearResultMapMarkers());
  },
  handleReportSidebarPropertyClick: (): void => {
    dispatch(
      reportEvent(
        'click_search_results_property',
        PARENT_EVENTS.CLICK_PROPERTY_CARD
      )
    );
  },
  handleReportMapPropertyClick: (): void => {
    dispatch(reportEvent('click_search_map_popup'));
  },
  getWatchListData: (): void => {
    dispatch(fetchWatchListIfNotInState());
  },
  handlePropertyCardHover: ({
    property,
    isHovered,
    isMarkerOnMap,
  }: {
    property: NormalizedProperty;
    isHovered: boolean;
    isMarkerOnMap: boolean;
  }) => {
    dispatch(searchPropertyCardHover({ property, isHovered, isMarkerOnMap }));
  },
  handleRemoveTemporaryMarker: (): void => {
    dispatch(searchRemoveTemporaryMarker());
  },
  handleToggleMobileFilters: (): void => {
    dispatch(reportEvent('click_filter_map'));
    dispatch(
      searchUpdateUrlParams({
        isShowingMobileFilters: true,
      })
    );
  },
  handleLoadMoreSidebarProperties: () => {
    dispatch(searchFetchMoreSidebarProperties());
  },
  handleGetMapLayerLegendBreaks: (
    bounds: LngLatBounds,
    zoom: number,
    metric?: LayerMetric
  ): void => {
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    dispatch(fetchMapLegendBreaks({ southWest, northEast, zoom, metric }));
  },
  highlightSchoolMarkers: ({
    addressSlug,
    gaiaSchoolsById,
  }: {
    addressSlug: string | null;
    gaiaSchoolsById: GaiaSchoolsById;
  }): void => {
    dispatch(searchHighlightSchoolMarkers({ addressSlug, gaiaSchoolsById }));
  },
  handleMapZoom: (isLayerEnabled: boolean, isZoomIn: boolean): void => {
    const eventName = isLayerEnabled
      ? 'click_heatmap_home_zoom'
      : isZoomIn
        ? 'click_map_home_zoom_in'
        : 'click_map_home_zoom_out';
    dispatch(reportEvent(eventName));
  },
  handleMapDrag: (isLayerEnabled: boolean): void => {
    const eventName = isLayerEnabled
      ? 'click_heatmap_home_drag'
      : 'click_map_home_drag';
    dispatch(reportEvent(eventName));
  },
  handleClearConstrainedToPlace: (): void => {
    dispatch(searchClearResultMapMarkers());
    dispatch(searchClearConstrainedToPlace());
  },
  handleOpenSearchList: (): void => {
    dispatch(
      searchUpdateUrlParams({
        isShowingSearchPageList: true,
      })
    );
    /* Load property list again with sorting for list view  */
    dispatch(
      searchFetchPropertyList({
        showLoadingIndicator: true,
      })
    );
  },
  handleNavigateToPDP: (normalizedPropertyData: NormalizedProperty): void => {
    dispatch(reportPropertyCardClick());
    dispatch(
      searchAddPropertyListPropertyToPropertyDataCache({
        addressSlug: normalizedPropertyData.slug,
        normalizedPropertyData: normalizedPropertyData,
      })
    );
  },
  handleSetMultiUnitPropertyData: (
    properties: MultiUnitDataObject[] | null
  ): void => {
    dispatch(reportEvent('click_search_map_marker'));
    dispatch(searchSetMultiUnitMarkerData(properties));
  },
  handleSetActiveMultiUnitProperties: (
    properties: MultiUnitDataObject[] | null
  ): void => {
    dispatch(searchSetActiveMultiUnitProperties(properties));
  },
  handleShowPDPModal: (markerFeatureProps: PropertyMapMarker): void => {
    dispatch(searchSetPDPModalMarkerDataAndShowModal(markerFeatureProps));
  },
  handleHidePDPModal: (): void => {
    dispatch(searchRouteChangeToHidePDPModal());
  },
  handleReportPropertyWatchClick: (slug: string) => {
    dispatch(
      reportEvent(
        'click_search_list_watch',
        PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
        { ch_property_id: slug }
      )
    );
  },
  handleReportPropertyUnwatchClick: (slug): void => {
    dispatch(
      reportEvent(
        'click_search_list_unwatch',
        PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
        { ch_property_id: slug }
      )
    );
  },
  handleReportPropertyUnwatchConfirmClick: (slug): void => {
    dispatch(
      reportEvent(
        'click_search_list_unwatch_confirm',
        PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
        { ch_property_id: slug }
      )
    );
  },
  handleReportSearchInputFocus: (): void => {
    dispatch(searchFieldClick());
  },
  handleToggleMapBottomLayerGroupsList: (shouldShow: boolean): void => {
    dispatch(searchToggleMapBottomLayerGroupsList(shouldShow));
  },
  handleReportShowingMapBottomLayerControl: (isShowing: boolean): void => {
    dispatch(searchReportShowingMapBottomLayerControl(isShowing));
  },
  handleSetMLSLayerFeatures: (
    features: (null | MLSCoverageLayerFeatureProperties)[]
  ): void => {
    dispatch(searchSetMLSLayerFeatures(features));
  },
  handleChangeSort: (
    sortField: SearchListSortField,
    sortOrder: SearchListSortOrder
  ): void => {
    dispatch(searchListApplySort({ sortField, sortOrder }));
  },
  reportHeatmapClick: (): void => {
    dispatch(reportEvent('click_heatmap_item'));
  },
  handleClickAutoCompleteSearchButton: (): void => {
    dispatch(reportClickSearchMapAutoCompleteSearchButton());
  },
  handleSelectCurrentLocation: () => {
    dispatch(searchGetUserLocationViaSelection());
    dispatch(reportSearchClickCurrentLocation());
  },
  handleSearchUpdateTextBox: (searchText: string, reportEvent: boolean) => {
    dispatch(searchUpdateTextBox(searchText));
    if (reportEvent) {
      dispatch(searchReportUpdateTextBox());
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPageMapWrapper);
