import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { hideOthers } from 'aria-hidden';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PureComponent } from 'react';
import { FocusOn } from 'react-focus-on';

import defaultTheme from '@client/css-modules/SlideInModal.css';
import iOSModalScrollingFix from '@client/hocs/ios-modal-scrolling-fix';
import Arrow from '@client/inline-svgs/arrow';
import CloseIcon from '@client/inline-svgs/close';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { key, stopEvent } from '@client/utils/component.utils';

// Distance below for desktop modal to animate from
const DESKTOP_OFFSET = 200;
const ScrollableContainer = iOSModalScrollingFix('div');

type Props = {
  isActive: boolean;
  /* If false, the modal will render as a contained window, center of screen: */
  isFullScreen?: boolean;
  handleClose: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLElement>
  ) => void;
  stickToBottomForSmallDevice?: boolean;
  handleKeyDown?: (e: React.KeyboardEvent) => void;
  hideCloseIcon?: boolean;
  /* Text to render inside of a styled header element */
  headingText?: React.ReactNode;
  theme: Theme;
  className?: string;
  modalAriaLabel: string;
  handleReturnFocus?: () => void;
  /* Not totally sure why TS is requiring this to be defined - it should be automatically defined as
   * part of PureComponent typing */
  children: React.ReactNode;
  /* Add option to blow out slide animation */
  withNoSlideEffect?: boolean;
  dataHcName?: string;
  /**
   * Fires when a modal have completed animating out.
   * e.g. refocus to a button after a modal is closed
   */
  handleModalExitAnimationComplete?: () => void;
};

type State = {
  modalHeight: number;
  windowHeight: number;
  resizeCount: number;
};

/**
 * A simple modal that animates-in when activated.
 *
 * If you want to have this modal render fullscreen on mobile, and regular on desktop,
 * you can use containers/slide-in-modal, which renders that at the x-small breakpoint.
 * otherwise, you can manually pass that prop.
 */
class SlideInModal extends PureComponent<Props, State> {
  state: State = {
    modalHeight: 0,
    windowHeight: 0,
    resizeCount: 0,
  };

  /* If prop isn't passed, default to `true` */
  getIsFullScreen = (): boolean => {
    const { isFullScreen } = this.props;
    return typeof isFullScreen === 'undefined' ? true : isFullScreen;
  };

  /**
   * If the modal is larger than the screen size, we don't want to have it fixed
   * and centered, it needs to be scrollable instead. We're setting window size and
   * the component size on state, and then changing styles based on if it's larger
   * than the window height.
   */
  setModalSizeOnState = (ele: HTMLElement | null): void => {
    if (ele && ele.parentNode && !this.getIsFullScreen()) {
      /*
       * This needs to attach to the child, since the parent ref is needed by
       * the ios-modal-scrolling-fix HOC;
       */
      const parent = ele.parentNode as Element;
      this.setState({
        modalHeight:
          (parent as HTMLElement).offsetHeight ||
          parent.scrollHeight ||
          parent.clientHeight,
      });
    }
  };

  setWindowDimensionsOnState = (): void => {
    this.setState({
      windowHeight: window.innerHeight,
    });
  };

  handleWindowResizing = (): void => {
    this.setWindowDimensionsOnState();
    this.setState({ resizeCount: this.state.resizeCount + 1 });
  };

  componentDidUpdate(prevProps: Props) {
    const isFullscreen = this.getIsFullScreen();
    if (this.props.isActive && !prevProps.isActive && !isFullscreen) {
      // set window size and add event listener for resizing window
      this.handleWindowResizing();
      window.addEventListener('resize', this.handleWindowResizing);
    } else if (!this.props.isActive && prevProps.isActive) {
      window.removeEventListener('resize', this.handleWindowResizing);
    }
    /* If provided, execute parent component's manual focusing of triggering element.  Otherwise,
     * FocusLock returnFocus will handle returning the focus.  This doesn't work in some cases on iOS
     * with VoiceOver enabled */
    if (
      !this.props.isActive &&
      prevProps.isActive &&
      this.props.handleReturnFocus
    ) {
      this.props.handleReturnFocus();
    }
  }

  componentDidMount() {
    this.handleWindowResizing();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResizing);
  }

  handleOnKeyDown = (e): void => {
    const { handleClose, handleKeyDown } = this.props;
    if (key.isEscape(e.key)) {
      stopEvent(e);
      handleClose(e);
    } else if (handleKeyDown) {
      handleKeyDown(e);
    }
  };

  handleCloseClick = (e): void => {
    stopEvent(e);
    this.props.handleClose(e);
  };

  handleCloseKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    stopEvent(e);
    this.props.handleClose(e);
  };

  render() {
    const {
      isActive,
      children,
      theme,
      hideCloseIcon,
      stickToBottomForSmallDevice,
      className,
      headingText,
      modalAriaLabel,
      handleReturnFocus,
      handleModalExitAnimationComplete,
      withNoSlideEffect,
      dataHcName,
    } = this.props;
    const { modalHeight, windowHeight } = this.state;
    const isFullScreen = this.getIsFullScreen();
    const extendsBeyondScreen = !isFullScreen && modalHeight >= windowHeight;

    return (
      <div data-hc-name={dataHcName}>
        {isActive && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            key="screen"
            className={className ? classNames(theme.Screen) : theme.Screen}
            onClick={this.handleCloseClick}
          />
        )}
        <AnimatePresence
          onExitComplete={
            handleModalExitAnimationComplete
              ? handleModalExitAnimationComplete
              : undefined
          }
        >
          {isActive && (
            <motion.div
              key="modal"
              initial={
                withNoSlideEffect
                  ? { opacity: isFullScreen ? 1 : 0 }
                  : {
                      y: isFullScreen ? 0 : DESKTOP_OFFSET,
                      x: isFullScreen ? '100vw' : 0,
                      opacity: isFullScreen ? 1 : 0,
                    }
              }
              animate={
                withNoSlideEffect
                  ? { opacity: 1 }
                  : {
                      y: 0,
                      x: 0,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                        easing: 'easeIn',
                      },
                    }
              }
              exit={
                withNoSlideEffect
                  ? { opacity: isFullScreen ? 1 : 0 }
                  : {
                      y: isFullScreen ? 0 : DESKTOP_OFFSET,
                      x: isFullScreen ? '100vw' : 0,
                      opacity: isFullScreen ? 1 : 0,
                      transition: {
                        duration: 0.3,
                        easing: 'easeOut',
                      },
                    }
              }
              onMouseDown={this.handleCloseClick}
              className={classNames(theme.ModalPositioner, {
                [theme.ModalPositionerOversize]: extendsBeyondScreen,
                [theme.ModalPositionerFullScreen]: isFullScreen,
                ...(className ? { [className]: className } : {}),
              })}
            >
              {/* Disable autoFocus so the outermost div itself can receive focus first */}
              <FocusOn
                // False positive - this is a proprietary prop on this custom component
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={false}
                className={theme.FocusLockWrapper}
                returnFocus={!handleReturnFocus}
                scrollLock={false}
                onActivation={() => {
                  const modal = document.getElementsByClassName(
                    classNames(theme.Modal)
                  )[0];
                  // everything else is "aria-hidden"
                  const undo = hideOthers(modal);
                  // undo changes
                  undo();
                }}
              >
                <ScrollableContainer
                  className={classNames(theme.ScrollableContainer, {
                    [theme.StickToBottom]: !!stickToBottomForSmallDevice,
                  })}
                  data-hc-name={'modal-scrollable-container'}
                  shouldAlwaysPreventBodyScrolling
                  key={`slideInModal${this.state.resizeCount}`}
                >
                  <div
                    role="dialog"
                    aria-label={modalAriaLabel}
                    onKeyDown={this.handleOnKeyDown}
                    onMouseDown={(e) => e.stopPropagation()}
                    className={classNames(theme.Modal, {
                      [theme.ModalWithCloseIcon]: !hideCloseIcon,
                      [theme.StickToBottom]: !!stickToBottomForSmallDevice,
                    })}
                  >
                    {
                      /* Hidden via CSS on large screens */
                      !hideCloseIcon && (
                        <div className={theme.MobileTopBar}>
                          <button
                            aria-label="Back"
                            className={theme.MobileCloseIconButton}
                            onClick={this.handleCloseClick}
                            onKeyDown={onEnterOrSpaceKey(
                              this.handleCloseKeyDown
                            )}
                          >
                            <Arrow />
                          </button>
                        </div>
                      )
                    }
                    {
                      /* Hidden via CSS on small screens */
                      !hideCloseIcon && (
                        <button
                          aria-label="Close Dialog"
                          data-hc-name="close-dialog-button"
                          className={theme.DesktopCloseIconButton}
                          onClick={this.handleCloseClick}
                        >
                          <CloseIcon className={theme.DesktopCloseIcon} />
                        </button>
                      )
                    }
                    {headingText && (
                      <div
                        className={theme.Heading}
                        role="heading"
                        aria-level={1}
                      >
                        {headingText}
                      </div>
                    )}
                    <div
                      className={theme.ChildrenWrapper}
                      ref={this.setModalSizeOnState}
                    >
                      {children}
                    </div>
                  </div>
                </ScrollableContainer>
              </FocusOn>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
}

const ThemedSlideInModal = themr(
  'ThemedSlideInModal',
  defaultTheme
)(SlideInModal);
export default ThemedSlideInModal;
