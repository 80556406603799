import { useFetchCensusTractDataEffect } from '@client/hooks/census-tract.hooks';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import DollarCircleIconSVG from '@client/inline-svgs/dollar-circle';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { selectPropertyCardGrandProgramLabel } from '@client/store/selectors/cobranding.selectors';
import {
  getReportEventPropsFromPropertyDetails,
  selectGrantAmountForTractId,
  selectIsSRPGrantFilterOn,
  setSelectedPropertyDetails,
} from '@client/store/slices/grant-program.slice';
import { NormalizedGrantProperty } from '@client/store/types/property';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

const flexedRowStyle = css`
  display: flex;
  align-items: center;
`;
const StyledRow = styled.div`
  ${flexedRowStyle}
`;
const StyledLabel = styled.div<{
  $color: string;
  hasMobileMapCloseButton?: boolean;
}>`
  ${(props) => `background-color: ${props.$color};`}
  ${flexedRowStyle}
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 2;
  font-size: 11px;
  color: #fff;
  padding: ${({ hasMobileMapCloseButton }) =>
    hasMobileMapCloseButton ? '5px 45px 5px 15px' : '5px 15px'};
  box-sizing: border-box;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  cursor: auto;
`;
const StyledDollarCircleIcon = styled(DollarCircleIconSVG)`
  margin-right: 5px;
  width: 20px;
  height: 20px;
  & path {
    stroke: #fff;
  }
`;
const StyledLearnMoreButton = styled.button`
  color: #fff;
  font-size: 11px;
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0px;
`;

export default function PropertyCardGrantProgramLabel({
  propertyDetails,
  hasMobileMapCloseButton,
}: {
  propertyDetails: NormalizedGrantProperty;
  hasMobileMapCloseButton?: boolean;
}) {
  const propertyTractId = propertyDetails.tractId;
  const reportEventProps =
    getReportEventPropsFromPropertyDetails(propertyDetails);
  const dispatch = useDispatch();
  const { primaryButtonFillColor } = useCobrandStyles();
  const isSRPGrantFilterOn = useSelector(selectIsSRPGrantFilterOn);
  const grantAmount = useSelector(selectGrantAmountForTractId(propertyTractId));
  const currentView = useSelector(getCurrentView);
  const isPermittedView = currentView && [View.SEARCH].includes(currentView);
  const customGrantProgramLabel = useSelector(
    selectPropertyCardGrandProgramLabel
  );
  const grantProgramLabel =
    customGrantProgramLabel || 'Qualifies for a $$$ grant';

  useFetchCensusTractDataEffect();

  return isSRPGrantFilterOn && isPermittedView && grantAmount ? (
    <StyledLabel
      $color={primaryButtonFillColor}
      onClick={(e) => e.stopPropagation()}
      data-hc-name="grant-program-card-label"
      aria-label="Grant Program Card Label"
      hasMobileMapCloseButton={hasMobileMapCloseButton}
    >
      <StyledRow>
        <StyledDollarCircleIcon aria-hidden="true" />
        {grantProgramLabel.replace('$$$', dollarsFormatter(grantAmount))}
      </StyledRow>
      <StyledLearnMoreButton
        data-hc-name="grant-program-learn-more-button"
        aria-label="Grant Program Learn More"
        onClick={() => {
          /* event propagation is stopped to prevent pdp click handler getting fired on this button trigger so we need to fire event manually here */
          dispatch(
            reportEvent('click_grant_info_srp_card', 'visits', reportEventProps)
          );
          dispatch(setSelectedPropertyDetails(propertyDetails));
        }}
      >
        Learn More
      </StyledLearnMoreButton>
    </StyledLabel>
  ) : (
    <></>
  );
}
