import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React from 'react';

import BrokerageAttributionComeHomeLogo from '@client/components/BrokerageAttributionComeHomeLogo';
import ClickableLogo from '@client/components/ClickableLogo';
import ComeHomeHeaderLogoByHouseCanaryCobranded from '@client/components/ComeHomeHeaderLogo/ComeHomeHeaderLogoByHouseCanaryCobranded';
import BrokerageAttributionContainer from '@client/containers/brokerage-attribution.container';
import HeaderBackArrowContainer from '@client/containers/header-back-arrow.container';
import defaultTheme from '@client/css-modules/HeaderMobileBottomSection.css';
import { View } from '@client/routes/constants';
import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

type Props = {
  isShowingBackNavButton?: boolean;
  currentView?: View | null;
  theme: Theme;
};

const HeaderMobileBottomSection: React.FC<Props> = ({
  isShowingBackNavButton,
  currentView,
  theme,
}) => {
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const isAlternateComehomeBrandingAndBrokerageDesignEnabled = useSelector(
    getIsFeatureEnabled('alternate_comehome_branding_and_brokerage_design')
  );

  return (
    <div className={theme.HeaderMobileBottomSection}>
      {isShowingBackNavButton && (
        <div className={theme.BackButtonWrapper}>
          <HeaderBackArrowContainer />
        </div>
      )}
      <div className={classNames(theme.ComeHomeLogoSection)}>
        <ClickableLogo
          shouldShowComehomeByHouseCanaryForWells
          dataHcName={'comehome-logo'}
          Logo={BrokerageAttributionComeHomeLogo}
          LogoByHouseCanary={ComeHomeHeaderLogoByHouseCanaryCobranded}
          theme={theme}
          alwaysUseRouteChange={isInsideNativeApp}
        />
      </div>
      {!isShowingBackNavButton &&
        !isAlternateComehomeBrandingAndBrokerageDesignEnabled && (
          <BrokerageAttributionContainer
            isMinimalStyling={true}
            theme={theme}
          />
        )}
    </div>
  );
};

export default themr(
  'ThemedHeaderMobileBottomSection',
  defaultTheme
)(HeaderMobileBottomSection);
