import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React from 'react';

import defaultTheme from '@client/css-modules/SchoolMapMarkerPopup.css';
import { titleCase } from '@client/utils/string.utils';

type Props = {
  featureProperties: {
    name: string;
    rank_unrounded?: number;
    uid: string;
  } | null;
  theme: Theme;
};

/**
 * A popup for school map markers
 */
export const SchoolMapMarkerPopup: React.FC<Props> = ({
  featureProperties,
  theme,
}) => {
  if (featureProperties) {
    const { name, rank_unrounded: rank, uid } = featureProperties;
    return (
      <div
        data-hc-name="school-marker-popup"
        data-school-id={uid}
        data-school-name={name}
        className={theme.SchoolMapMarkerPopup}
        aria-labelledby={`title-${uid}`}
        role="dialog"
        tabIndex={-1}
      >
        <div id={`title-${uid}`} className={theme.SchoolName}>
          {titleCase(name)}
        </div>
        {rank && rank >= 0 && (
          <div className={theme.RankingCol}>
            <div className={theme.topLine}>Ranking</div>
            <div className={theme.midLine}>{Math.round(rank)}</div>
            <div className={theme.bottomLine}>Percentile</div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export const ThemedSchoolMapMarkerPopup = themr(
  'SchoolMapMarkerPopup',
  defaultTheme
)(SchoolMapMarkerPopup);
export default ThemedSchoolMapMarkerPopup;
