/**
 * Keep top-level keys in alpha order please
 */
export default ({
  slug,
  photoSize,
  dateFiveYearsAgo,
  dateOneYearAhead,
  dateThreeYearsAhead,
}: {
  slug: string;
  photoSize: 'SMALL' | 'MEDIUM' | 'LARGE';
  dateFiveYearsAgo: string;
  dateOneYearAhead: string;
  dateThreeYearsAhead: string;
}) => ({
  query: /* GraphQL */ `
    query PropertyDetailsFull(
      $slug: String
      $photoSize: ImageSize
      $dateFiveYearsAgo: Date
      $dateOneYearAhead: Date
      $dateThreeYearsAhead: Date
    ) {
      propertyLookup(id: { slug: $slug }) {
        address {
          slug
          fullAddress
          hcAddressId
          streetAddress
          unit
          city
          state
          zipcode
          zipcodePlus4
        }
        association {
          name
          fees {
            amount
            frequency
            notes
          }
        }
        avm(qualityMethod: CONSUMER) {
          priceUpper
          priceLower
          fsd
          quality
          priceMean
        }
        bestPhotos(orderBy: MLS) {
          id
          storageUrl
          prediction
          confidence
          representation(size: $photoSize) {
            httpUrl
            height
            width
          }
        }
        block {
          tractId
          valueTs(start: $dateFiveYearsAgo, end: $dateOneYearAhead) {
            adjustedValue
            month
          }
          crime {
            all {
              nationPercentile
              countyPercentile
            }
            property {
              nationPercentile
              countyPercentile
            }
            violent {
              nationPercentile
              countyPercentile
            }
          }
          valueTsForecastSummary {
            year
            value
            percent
          }
          histograms {
            age(rebin: { bins: 5, method: TAILS }) {
              count
              end
              start
            }
            baths(rebin: { bins: 5, method: TAILS }) {
              count
              end
              start
            }
            beds(rebin: { bins: 5, method: TAILS }) {
              count
              end
              start
            }
            buildingArea(rebin: { bins: 5, method: TAILS }) {
              count
              end
              start
            }
            valuePerSqft(rebin: { bins: 5, method: TAILS }) {
              count
              end
              start
            }
          }
        }
        hcBuildingId
        comps(limit: 6, minScore: 85, includeActive: false) {
          rentalAvm(qualityMethod: CONSUMER) {
            priceMean
            priceUpper
            priceLower
            fsd
            quality
          }
          avm {
            priceMean
          }
          address {
            slug
            fullAddress
            hcAddressId
            streetAddress
            unit
            city
            state
            zipcode
            zipcodePlus4
          }
          bestPhotos(limit: 1, orderBy: MLS) {
            id
            storageUrl
            prediction
            confidence
            representation {
              httpUrl
              height
              width
            }
          }
          geoLocation {
            latitude
            longitude
          }
          listPrice
          livingSpace {
            livingArea
            bedrooms {
              count
            }
            bathrooms {
              summaryCount
            }
          }
          mls {
            regulations {
              logoOverlay
              photosLogoOverlay
            }
          }
          propertyType
          summary {
            mlsState
            listPrice
            hcBuildingId
            score {
              default {
                dollars
                level
                similarity
              }
            }
          }
        }
        county {
          name
        }
        geoLocation {
          latitude
          longitude
        }
        latestAssessment {
          taxSummary {
            amount
            year
          }
        }
        latestListing {
          agentName
          agentEmail
          agentPhone
          agentLicense
          listingOfficeName
          buyerBrokerageCompensationDisplay
          openHouse(status: ACTIVE) {
            appointmentRequired
            date
            description
            directions
            end
            remarks
            start
          }
          publicRemarks
          status
          statusDate
          price
          listingID
        }
        listDate
        listPrice
        livingSpace {
          numberOfRooms
          livingArea
          bedrooms {
            count
          }
          bathrooms {
            summaryCount
          }
        }
        mls {
          mlsID
          name
          abbreviation
          lastRefreshed
          regulations {
            copyrightStatement
            disclaimer
            logo
            logoOverlay
            photosClosedLogin
            photosLogoOverlay
          }
        }
        mlsState
        nearbyListings(limit: 10, minScore: 85) {
          address {
            slug
            fullAddress
            hcAddressId
            streetAddress
            unit
            city
            state
            zipcode
            zipcodePlus4
          }
          avm {
            priceMean
          }
          bestPhotos(limit: 6, orderBy: MLS) {
            id
            storageUrl
            prediction
            confidence
            representation(size: MEDIUM) {
              httpUrl
              height
              width
            }
          }
          geoLocation {
            latitude
            longitude
          }
          summary {
            baths
            beds
            latitude
            longitude
            listDate
            listPrice
            mlsState
            mlsStateDate
            sqft
            propertyType
          }
          mls {
            abbreviation
            lastRefreshed
            mlsID
            name
            regulations {
              logoOverlay
              photosLogoOverlay
            }
          }
        }
        propertyType
        parcel {
          geometry
        }
        rentalAvm(qualityMethod: CONSUMER) {
          priceMean
          priceUpper
          priceLower
          fsd
          quality
        }
        rentalYield
        schools {
          id
          name
          levels
          distanceMiles
          score
        }
        schoolsSummary
        site {
          area(units: SQFT)
          areaDescription
          zoning {
            code
          }
        }
        structure {
          yearBuilt
          stories
        }
        transfers(order: DESCENDING) {
          transferDate
          transferPrice
          eventType
        }
        zip {
          hpi(start: $dateFiveYearsAgo, end: $dateThreeYearsAhead) {
            adjustedValue
            month
          }
          hpiForecastSummary {
            year
            value
            percent
          }
        }
        floodZone {
          effectiveDate
          floodRisk
          floodRiskDisplay
          panelNumber
          zone
        }
        paymentEstimate {
          associationFee
          pmi
          loan
          tax
          total
        }
      }
    }
  `,
  variables: {
    slug,
    photoSize,
    dateFiveYearsAgo,
    dateOneYearAhead,
    dateThreeYearsAhead,
  },
});
