import CircleButton from '@client/components/generic/CircleButton';
import defaultTheme from '@client/css-modules/RequestATourButton.css';
import { useRequestATourState } from '@client/hooks/request-a-tour.hooks';
import PersonOnCircle from '@client/inline-svgs/person-on-circle';
import { getPropertyDetailPageConfig } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';

type Props = {
  theme: Theme;
};

const RequestATourButton = ({ theme }: Props) => {
  const { openRequestATourModal } = useRequestATourState();
  const isRequestATourEnabled = useSelector(
    getIsFeatureEnabled('request_a_tour')
  );
  const propertyDetailPageConfig = useSelector(getPropertyDetailPageConfig);
  const shouldDisplayButton =
    isRequestATourEnabled &&
    !propertyDetailPageConfig.iconCtaText &&
    !propertyDetailPageConfig.iconCtaUrl;

  if (!shouldDisplayButton) {
    return null;
  }

  return (
    <div className={theme.RequestATourButton}>
      <CircleButton
        data-event-name="click_request_a_tour_PDP_button"
        data-parent-event-name="click_request_a_tour"
        ariaLabel="Request tour"
        theme={theme}
        onClick={openRequestATourModal}
      >
        <PersonOnCircle className={theme.PersonOnCircle} />
      </CircleButton>
      <div className={theme.ButtonLabel}>Request tour</div>
    </div>
  );
};

const ThemedRequestATourButton = themr(
  'RequestATourButton',
  defaultTheme
)(RequestATourButton);
export default ThemedRequestATourButton;
