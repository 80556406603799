import logoSrc from '@client/assets/images/lendco-logo.png';
import React from 'react';

type Props = {
  shouldUseAltText?: boolean;
};

/**
 * An fallback header logo when none is defined in Parcon for a cobrand
 */
const LendcoLogo: React.FC<Props & React.HTMLAttributes<HTMLImageElement>> = ({
  shouldUseAltText,
  ...rest
}) => {
  return (
    <img alt={shouldUseAltText ? 'Lendco logo' : ''} src={logoSrc} {...rest} />
  );
};

export default LendcoLogo;
