import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { debounce, isEqual } from 'lodash';
import React from 'react';

import BuyingPowerPill from '@client/components/BuyingPowerPill';
import CobrandedStyles from '@client/components/CobrandedStyles';
import MaxOnlyPicker from '@client/components/generic/MaxOnlyPicker';
import MinMaxPicker from '@client/components/generic/MinMaxPicker';
import MinOnlyButtons from '@client/components/generic/MinOnlyButtons';
import MinOnlyPicker from '@client/components/generic/MinOnlyPicker';
import MobileCheckboxPicker from '@client/components/generic/MobileCheckboxPicker';
import MobileFiltersSlider from '@client/components/generic/MobileFiltersSlider';
import defaultTheme from '@client/css-modules/MobileFilterControl.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import {
  CONTROL_TYPES,
  FILTER_KEYS,
  FilterKey,
  FilterMinMaxNumberValue,
  FilterMinMaxStringValue,
  FilterPropertyTypes,
  FiltersState,
  FilterValue,
} from '@client/store/filter-constants';
import { MlsStateGroup } from '@client/store/sagas/queries/types';
import { FilterPickerValue } from '@client/store/types/filters';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import {
  getControlFormatterForFilter,
  getFilterDef,
  getIndexForInsertingBuyingPower,
  getLabelFormatterForFilter,
  getSetStateFormatterForFilter,
} from '@client/utils/filters.utils';
import { getPropertyCountDisplay } from '@client/utils/property.utils';

const { headerAndControlsCombinedHeight } = defaultTheme;

/**
 * Type guards to allow us to properly type each filter control's props
 */
const isCheckboxPickerControlTypeForValue = (
  value: FilterValue
): value is FilterPropertyTypes[] | MlsStateGroup[] => {
  return Array.isArray(value);
};
const isMaxOnlyPickerControlTypeForValue = (
  value: FilterValue
): value is [null, number | null] => {
  return (
    Array.isArray(value) &&
    value[0] === null &&
    (value[1] === null || typeof value[1] === 'number')
  );
};
const isMinOnlyPickerControlTypeForValue = (
  value: FilterValue
): value is [number | null, null] => {
  return (
    Array.isArray(value) &&
    (value[0] === null || typeof value[0] === 'number') &&
    value[1] === null
  );
};
const isMinOnlyButtonsControlTypeForValue = (
  value: FilterValue
): value is [number | null, null] => {
  return (
    Array.isArray(value) &&
    (value[0] === null || typeof value[0] === 'number') &&
    value[1] === null
  );
};
const isMinMaxPickerControlTypeForValue = (
  value: FilterValue
): value is FilterMinMaxNumberValue => {
  return (
    Array.isArray(value) &&
    (value[0] === null || typeof value[0] === 'number') &&
    (value[1] === null || typeof value[1] === 'number')
  );
};
const isMinMaxSliderControlTypeForValue = (
  value: FilterValue
): value is FilterMinMaxNumberValue | FilterMinMaxStringValue => {
  return (
    Array.isArray(value) &&
    (value[0] === null ||
      typeof value[0] === 'number' ||
      typeof value[0] === 'string') &&
    (value[1] === null ||
      typeof value[1] === 'number' ||
      typeof value[1] === 'string')
  );
};

type Props = {
  handleValueChange: (key: string | null, values: FilterValue) => void;
  setInitialFilterValue: (
    filterKey: FilterKey,
    filterValue: FilterValue
  ) => void;
  clearInitialFilterValue: () => void;
  handleClose: () => void;
  handleCancel?: () => void;
  isDisplayMultiFamilySearchFiltersEnabled: boolean;
  isShowComingSoonInListingStatusFilterEnabled: boolean;
  reportQuickFilterMobileCancelClick: (filterKey: string) => void;
  initialValue: {
    key: string | null;
    values: FilterValue;
  };
  filterKey: FilterKey;
  filterValuesByKey: FiltersState;
  needsMobileHeightAdjustment: boolean;
  userBuyingPower: number | null;
  cobrandDisplayName: string;
  activeBuyingPowerColor: string;
  theme: Theme;
  propertyCount: number | null;
  onTitleClick?: (e: React.MouseEvent<HTMLElement>) => void;
  isXXSmallScreenSize: boolean;
  handleApply?: (hasChanged: boolean) => void;
  reportQuickFilterMobileApplyClick: (filterKey: FilterKey) => void;
  reportBuyingPowerOkClick: () => void;
  reportBuyingPowerCancelClick: () => void;
  reportValueSelection: (
    filterKey: FilterKey,
    descriptor: string | null,
    value: FilterPickerValue | null
  ) => void;
  maxResultCount: number;
};

class MobileFilterControl extends React.Component<Props> {
  componentDidMount() {
    const { setInitialFilterValue, filterKey, filterValuesByKey } = this.props;
    setInitialFilterValue(filterKey, filterValuesByKey[filterKey]);
  }

  componentWillUnmount() {
    this.props.clearInitialFilterValue();

    if (this.debouncedMinMaxValueChangeHandler) {
      this.debouncedMinMaxValueChangeHandler.cancel();
    }
  }

  /**
   * Revert filter back to its original value and close the control
   * @return {void}
   */
  handleCancelClick = () => {
    const {
      filterKey,
      filterValuesByKey,
      handleValueChange,
      handleCancel,
      handleClose,
      initialValue,
      reportQuickFilterMobileCancelClick,
    } = this.props;
    const { key, values } = initialValue;

    /* Only explicitly revert value if it's changed from the initial value */
    if (!isEqual(filterValuesByKey[filterKey], values)) {
      handleValueChange(key, values);
    }
    reportQuickFilterMobileCancelClick(filterKey);
    handleCancel?.();
    handleClose();
  };

  handleApplyClick = () => {
    const { filterKey, filterValuesByKey, initialValue, handleApply } =
      this.props;
    const { values } = initialValue;
    if (handleApply) {
      handleApply(!isEqual(filterValuesByKey[filterKey], values));
    }
    this.props.reportQuickFilterMobileApplyClick(filterKey);
    this.props.handleClose();
  };

  /**
   * Return a formatter to be used specifically for the LIST_PRICE_MIN_MAX filter when
   * the user has a defined buying power.  This formatter distinctly formats the buying
   * power value in the list of value options.
   * @param  {number} value
   * @param  {boolean} minOrMax
   * @return {React.ComponentType}
   */
  listPriceWithBuyingPowerLabelFormatter = (
    value: FilterPickerValue,
    minOrMax: string
  ): string | JSX.Element => {
    const { userBuyingPower, cobrandDisplayName, activeBuyingPowerColor } =
      this.props;
    const formattedLabel = getLabelFormatterForFilter(
      FILTER_KEYS.LIST_PRICE_MIN_MAX
    )(value);

    return value === userBuyingPower && minOrMax === 'max' ? (
      <div style={{ color: activeBuyingPowerColor }}>
        <div className={defaultTheme.BuyingPowerValue}>{formattedLabel}</div>
        <div className={defaultTheme.BuyingPowerSubtext}>
          {cobrandDisplayName
            ? `${cobrandDisplayName} buying power`
            : 'Buying power'}
        </div>
      </div>
    ) : (
      formattedLabel
    );
  };

  localHandleReportValueWithDescriptorSelection = (
    descriptor: string,
    value: FilterPickerValue
  ) => {
    const { filterKey, reportValueSelection } = this.props;
    const valueLowerCase =
      typeof value === 'string' ? value.toLocaleLowerCase() : null;
    reportValueSelection(filterKey, descriptor, valueLowerCase || value);
  };

  localHandleReportValueSelection = (value: FilterPickerValue | null) => {
    const { filterKey, reportValueSelection } = this.props;
    const valueLowerCase =
      typeof value === 'string' ? value.toLocaleLowerCase() : null;
    reportValueSelection(filterKey, null, valueLowerCase || value);
  };

  handleCheckboxPickerOnChange = (value: (string | number)[]) => {
    const { filterKey, isDisplayMultiFamilySearchFiltersEnabled } = this.props;
    this.props.handleValueChange(
      filterKey,
      value as FilterPropertyTypes[] | MlsStateGroup[]
    );
    if (
      isDisplayMultiFamilySearchFiltersEnabled &&
      filterKey === FILTER_KEYS.PROPERTY_TYPE &&
      !value.includes('MULTI')
    ) {
      this.props.handleValueChange(FILTER_KEYS.UNITS_TOTAL_MIN_MAX, [
        null,
        null,
      ]);
    }
  };

  handleMinMaxValueChange = (
    value: [FilterPickerValue | null, FilterPickerValue | null]
  ) => {
    const { filterKey } = this.props;
    this.props.handleValueChange(filterKey, value as FilterMinMaxNumberValue);
  };
  debouncedMinMaxValueChangeHandler = debounce(
    this.handleMinMaxValueChange,
    300
  );

  render() {
    const {
      theme,
      filterKey,
      propertyCount,
      handleClose,
      isDisplayMultiFamilySearchFiltersEnabled,
      isShowComingSoonInListingStatusFilterEnabled,
      onTitleClick,
      needsMobileHeightAdjustment,
      handleValueChange,
      isXXSmallScreenSize,
      filterValuesByKey,
      userBuyingPower,
      reportBuyingPowerOkClick,
      reportBuyingPowerCancelClick,
      maxResultCount,
    } = this.props;

    const windowHeight = window ? window.innerHeight : 0;
    const filterDef = getFilterDef(filterKey);
    const filterControlType = filterDef.control_type;
    const filterTitle = filterDef.displayable_title;
    const filterDescription = filterDef.description_short;
    const filterValue = filterValuesByKey[filterKey];
    const filterValues = filterDef.value_list;
    let filterValuesArr = [...filterValues];
    if (
      !isDisplayMultiFamilySearchFiltersEnabled &&
      filterKey === FILTER_KEYS.PROPERTY_TYPE
    ) {
      filterValuesArr = [...filterValues].filter((value) => value !== 'MULTI');
    }
    if (
      !isShowComingSoonInListingStatusFilterEnabled &&
      filterKey === FILTER_KEYS.MLS_STATE
    ) {
      filterValuesArr = [...filterValues].filter(
        (value) => value !== 'COMING_SOON'
      );
    }
    /* We need to insert the buying power value into the list of values if it's present in the app state
     * but not already present in the list of value options */
    if (
      userBuyingPower &&
      filterKey === FILTER_KEYS.LIST_PRICE_MIN_MAX &&
      filterValuesArr.indexOf(userBuyingPower) === -1
    ) {
      const spliceIndex = getIndexForInsertingBuyingPower(
        filterValuesArr as number[],
        userBuyingPower
      );
      filterValuesArr.splice(spliceIndex, 0, userBuyingPower);
    }

    return (
      <CobrandedStyles>
        {({ primaryButtonFillColor }) => (
          <AccessibleElementUniqueId>
            {({ uid }) => (
              <div
                className={classNames(theme.MobileFilterControl, {
                  [theme.MobileFilterControlAllowingOverflow]:
                    userBuyingPower &&
                    filterKey === FILTER_KEYS.LIST_PRICE_MIN_MAX,
                })}
                style={{
                  ...(needsMobileHeightAdjustment
                    ? {
                        maxHeight: `calc(${windowHeight}px - ${headerAndControlsCombinedHeight})`,
                      }
                    : {}),
                }}
              >
                <div className={theme.Header}>
                  <h2
                    id={uid}
                    className={theme.SelectedFilter}
                    onClick={onTitleClick}
                  >
                    {filterTitle}
                  </h2>
                  {userBuyingPower &&
                    filterKey === FILTER_KEYS.LIST_PRICE_MIN_MAX && (
                      <BuyingPowerPill
                        value={userBuyingPower}
                        onClick={() => {
                          handleValueChange(FILTER_KEYS.LIST_PRICE_MIN_MAX, [
                            null,
                            userBuyingPower,
                          ]);
                          handleClose();
                        }}
                      />
                    )}
                  <div className={theme.Results}>
                    {getPropertyCountDisplay(propertyCount, maxResultCount)}
                  </div>
                </div>
                <div className={theme.FilterControl}>
                  <section className={theme.Filter}>
                    {filterDescription && (
                      <div className={theme.Description}>
                        {filterDescription}
                      </div>
                    )}
                    <div className={theme.Controls}>
                      {(() => {
                        if (
                          filterControlType === CONTROL_TYPES.CHECKBOX_PICKER &&
                          isCheckboxPickerControlTypeForValue(filterValue)
                        ) {
                          return (
                            <MobileCheckboxPicker
                              ariaLabelledBy={uid}
                              currentValues={filterValue}
                              theme={theme}
                              filterKey={filterKey}
                              description={filterDescription}
                              values={filterValuesArr}
                              getValueForControlFormatter={getControlFormatterForFilter(
                                filterKey,
                                isDisplayMultiFamilySearchFiltersEnabled
                              )}
                              setValueForStateFormatter={getSetStateFormatterForFilter(
                                filterKey,
                                isDisplayMultiFamilySearchFiltersEnabled
                              )}
                              labelFormatter={getLabelFormatterForFilter(
                                filterKey
                              )}
                              onChange={this.handleCheckboxPickerOnChange}
                              handleReportValueSelection={
                                this.localHandleReportValueSelection
                              }
                            />
                          );
                        } else if (
                          filterControlType === CONTROL_TYPES.MAX_ONLY_PICKER &&
                          isMaxOnlyPickerControlTypeForValue(filterValue)
                        ) {
                          return (
                            <MaxOnlyPicker
                              ariaLabelledBy={uid}
                              currentValues={filterValue}
                              theme={theme}
                              filterKey={filterKey}
                              values={filterValuesArr}
                              getValueForControlFormatter={getControlFormatterForFilter(
                                filterKey
                              )}
                              setValueForStateFormatter={getSetStateFormatterForFilter(
                                filterKey
                              )}
                              labelFormatter={getLabelFormatterForFilter(
                                filterKey
                              )}
                              onChange={this.debouncedMinMaxValueChangeHandler}
                              height={isXXSmallScreenSize ? 100 : undefined}
                              handleReportValueSelection={
                                this.localHandleReportValueSelection
                              }
                            />
                          );
                        } else if (
                          filterControlType === CONTROL_TYPES.MIN_MAX_PICKER &&
                          isMinMaxPickerControlTypeForValue(filterValue)
                        ) {
                          return (
                            <MinMaxPicker
                              ariaLabelledBy={uid}
                              currentValues={filterValue}
                              theme={theme}
                              filterKey={filterKey}
                              values={filterValuesArr}
                              getValueForControlFormatter={getControlFormatterForFilter(
                                filterKey
                              )}
                              setValueForStateFormatter={getSetStateFormatterForFilter(
                                filterKey
                              )}
                              labelFormatter={
                                userBuyingPower &&
                                filterKey === FILTER_KEYS.LIST_PRICE_MIN_MAX
                                  ? this.listPriceWithBuyingPowerLabelFormatter
                                  : getLabelFormatterForFilter(filterKey)
                              }
                              onChange={this.debouncedMinMaxValueChangeHandler}
                              height={isXXSmallScreenSize ? 100 : undefined}
                              confirmIfGreaterThanValue={
                                filterKey === FILTER_KEYS.LIST_PRICE_MIN_MAX &&
                                userBuyingPower
                                  ? userBuyingPower
                                  : undefined
                              }
                              confirmForOptionGroup={'max'}
                              confirmationText={
                                'That price is above your buying power. Would you like to continue?'
                              }
                              onConfirmationConfirm={reportBuyingPowerOkClick}
                              onConfirmationCancel={() => {
                                reportBuyingPowerCancelClick();
                                handleValueChange(
                                  FILTER_KEYS.LIST_PRICE_MIN_MAX,
                                  [null, userBuyingPower]
                                );
                                handleClose();
                              }}
                              handleReportValueSelection={
                                this
                                  .localHandleReportValueWithDescriptorSelection
                              }
                            />
                          );
                        } else if (
                          filterControlType === CONTROL_TYPES.MIN_ONLY_PICKER &&
                          isMinOnlyPickerControlTypeForValue(filterValue)
                        ) {
                          return (
                            <MinOnlyPicker
                              ariaLabelledBy={uid}
                              currentValues={filterValue}
                              theme={theme}
                              filterKey={filterKey}
                              values={filterValuesArr}
                              getValueForControlFormatter={getControlFormatterForFilter(
                                filterKey
                              )}
                              setValueForStateFormatter={getSetStateFormatterForFilter(
                                filterKey
                              )}
                              labelFormatter={getLabelFormatterForFilter(
                                filterKey
                              )}
                              onChange={this.debouncedMinMaxValueChangeHandler}
                              height={isXXSmallScreenSize ? 100 : undefined}
                              handleReportValueSelection={
                                this.localHandleReportValueSelection
                              }
                            />
                          );
                        } else if (
                          filterControlType ===
                            CONTROL_TYPES.MIN_ONLY_BUTTONS &&
                          isMinOnlyButtonsControlTypeForValue(filterValue)
                        ) {
                          return (
                            <MinOnlyButtons
                              currentValues={filterValue}
                              theme={theme}
                              filterKey={filterKey}
                              values={filterValuesArr}
                              getValueForControlFormatter={getControlFormatterForFilter(
                                filterKey
                              )}
                              setValueForStateFormatter={getSetStateFormatterForFilter(
                                filterKey
                              )}
                              labelFormatter={getLabelFormatterForFilter(
                                filterKey
                              )}
                              onChange={this.debouncedMinMaxValueChangeHandler}
                              handleReportValueSelection={
                                this.localHandleReportValueSelection
                              }
                            />
                          );
                        } else if (
                          filterControlType === CONTROL_TYPES.MIN_MAX_SLIDER &&
                          isMinMaxSliderControlTypeForValue(filterValue)
                        ) {
                          return (
                            <MobileFiltersSlider
                              ariaLabelledBy={uid}
                              currentValues={filterValue}
                              theme={theme}
                              filterKey={filterKey}
                              values={filterValuesArr}
                              getValueForControlFormatter={getControlFormatterForFilter(
                                filterKey
                              )}
                              setValueForStateFormatter={getSetStateFormatterForFilter(
                                filterKey
                              )}
                              labelFormatter={getLabelFormatterForFilter(
                                filterKey
                              )}
                              onChange={this.debouncedMinMaxValueChangeHandler}
                              handleReportValueSelection={
                                this.localHandleReportValueSelection
                              }
                            />
                          );
                        } else {
                          throw new Error(
                            `No filter component found for key ${filterControlType} with filter value ${JSON.stringify(
                              filterValue
                            )}`
                          );
                        }
                      })()}
                    </div>
                  </section>
                  <div className={theme.ControlActionButtons}>
                    <button
                      type="button"
                      style={{
                        color: primaryButtonFillColor,
                        borderColor: primaryButtonFillColor,
                      }}
                      onClick={this.handleCancelClick}
                      onKeyDown={onEnterOrSpaceKey(this.handleCancelClick)}
                      className={classNames(theme.ActionButton, theme.cancel)}
                      data-hc-name="cancel-button"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      style={{ background: primaryButtonFillColor }}
                      onClick={this.handleApplyClick}
                      onKeyDown={onEnterOrSpaceKey(this.handleApplyClick)}
                      className={classNames(theme.ActionButton, theme.apply)}
                      data-hc-name="apply-button"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            )}
          </AccessibleElementUniqueId>
        )}
      </CobrandedStyles>
    );
  }
}

export default themr('MobileFilterControl', defaultTheme)(MobileFilterControl);
