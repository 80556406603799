import PoweredByHouseCanaryLogoWhiteSvg from '@client/assets/images/powered-by-housecanary-white.svg';
import PoweredByHouseCanaryLogoSvg from '@client/assets/images/powered-by-housecanary.svg';
import React from 'react';

interface PoweredByHouseCanaryProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  useWhiteLogo?: boolean;
}

const PoweredByHouseCanaryLogo: React.FunctionComponent<
  PoweredByHouseCanaryProps
> = (props) => {
  const { useWhiteLogo, ...rest } = props;
  return (
    <img
      src={
        useWhiteLogo
          ? PoweredByHouseCanaryLogoWhiteSvg
          : PoweredByHouseCanaryLogoSvg
      }
      alt={'Powered by HouseCanary Logo'}
      {...rest}
    />
  );
};

export default PoweredByHouseCanaryLogo;
