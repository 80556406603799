import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import HeaderCobranded from '@client/components/Header/HeaderCobranded';
import { ALL_SEARCH_VIEWS, View } from '@client/routes/constants';
import { fetchAlertsUnreadCount } from '@client/store/actions/alerts.actions';
import {
  reportClickLenderNavBackToSite,
  reportClickLenderNavLink1,
  reportClickLenderNavLink2,
  reportClickLenderNavLink3,
  reportClickSearchMapAutoCompleteSearchButton,
  reportEvent,
} from '@client/store/actions/analytics.actions';
import {
  authModalShow,
  logOut,
  logOutThenRedirect,
} from '@client/store/actions/auth.actions';
import { openModal } from '@client/store/actions/modals.actions';
import {
  searchClearConstrainedToPlace,
  searchClearResultMapMarkers,
} from '@client/store/actions/search.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { ModalKey } from '@client/store/constants';
import { getUnreadAlertsCount } from '@client/store/selectors/alerts.selectors';
import {
  getAccessToken,
  getChapiPartnerInviteId,
  getHasMultipleHomes,
  getIsLoggedIn,
  getUserContactInfo,
  getUserId,
  getUserPartnerUserId,
} from '@client/store/selectors/auth.selectors';
import {
  getBackToSiteLinkAndText,
  getCobrandDisplayName,
  getCobrandId,
  getCustomNavCtas,
  getGrantProgramConfig,
  getIsInsideNativeApp,
  getNavLinkLabels,
  getPartialHeaderLinks,
  getTopLocalAgentAdDetails,
} from '@client/store/selectors/cobranding.selectors';
import { getIsEmailOptInModalAllowedToOpenAndNoOtherModalOpen } from '@client/store/selectors/email-opt-in.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getHasFullWidthDesktopHeaderForView,
  getIsHidingPersistentHeaderSearchForPageOnTablet,
  getIsShowingBackNavButtonForView,
  getIsShowingPersistentSearchField,
  getIsShowingSearchIconForRoute,
} from '@client/store/selectors/global-ui.selectors';
import { getClaimedHomes } from '@client/store/selectors/homeowner.selectors';
import { getIsShowingHeaderLenderCTA } from '@client/store/selectors/loan-officer.selectors';
import {
  getIsAndroidDevice,
  getIsSmallSize,
  getIsTabletOrSmallerSize,
} from '@client/store/selectors/match-media.selectors';
import {
  getSearchConstrainedPlace,
  getSearchPlaceGeoJSONDescription,
} from '@client/store/selectors/search.selectors';
import {
  getScaffoldingCMGHeaderDataForDesktop,
  getScaffoldingCMGHeaderDataForMobile,
} from '@client/store/slices/scaffolding-data.slice';
import { AuthModalPage } from '@client/store/types/auth';

type OwnProps = {
  isHidingPersistentSearchField?: boolean;
  hasVisibleLabel?: boolean;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const isSmallScreen = getIsSmallSize(state);
  const currentView = getCurrentView(state);
  const isShowingPersistentSearchField =
    getIsShowingPersistentSearchField(state) &&
    !ownProps.isHidingPersistentSearchField;
  const isInsideNativeApp = getIsInsideNativeApp(state);
  const isLoggedIn = getIsLoggedIn(state);
  const isReferralServicesEnabled =
    getIsFeatureEnabled('referral_services')(state);

  const isGrantProgramEnabled = getIsFeatureEnabled('grant_program')(state);
  const isYourTeamEnabled = getIsFeatureEnabled('your_team')(state);
  const grantProgramConfig = getGrantProgramConfig(state);
  const grantProgramHeaderLinkText =
    grantProgramConfig?.gp_header_link.button_text;
  const grantProgramHeaderLinkUrl =
    grantProgramConfig?.gp_header_link.destination_url;
  const nativeAppNavKey = grantProgramConfig?.gp_header_link.native_app_nav_key;
  return {
    currentView,
    isLoggedIn,
    isCanaryUIFeatureEnabled: getIsFeatureEnabled('canary_ui')(state),
    isReferralServicesEnabled,
    isRecentUserActivityEnabled: getIsFeatureEnabled('recent_user_activity')(
      state
    ),
    isScaffoldingDataForHeaderDesktop:
      !!getScaffoldingCMGHeaderDataForDesktop(state),
    isScaffoldingDataForHeaderMobile:
      !!getScaffoldingCMGHeaderDataForMobile(state),
    userId: getUserId(state),
    /* Only displayed on small screens via CSS */
    isShowingSearchIconForRoute: getIsShowingSearchIconForRoute(state),
    isShowingPersistentSearchFieldBelowHeader: isShowingPersistentSearchField,
    isShowingSearchFieldInsideHeader:
      !isSmallScreen && !isShowingPersistentSearchField,
    /* Display back button on secondary nav */
    isShowingBackNavButton: getIsShowingBackNavButtonForView(state),
    unreadAlertsCount: getUnreadAlertsCount(state),
    placeGeoJSONDescription:
      currentView && ALL_SEARCH_VIEWS.indexOf(currentView) > -1
        ? getSearchPlaceGeoJSONDescription(state)
        : undefined,
    shouldClearConstrainedPlaceOnClearingSearch:
      !!getSearchConstrainedPlace(state),
    shouldCloseMenuOnAddressSearchResultClick: !!(
      currentView && ALL_SEARCH_VIEWS.indexOf(currentView) === -1
    ),
    shouldDisplayCurrentLocationSearchOption: !!(
      isSmallScreen &&
      currentView &&
      ALL_SEARCH_VIEWS.indexOf(currentView) > -1
    ),
    isDesktopHeaderFullWidth: getHasFullWidthDesktopHeaderForView(state),
    hasMultipleHomes: getHasMultipleHomes(state),
    claimedHomes: getClaimedHomes(state),
    /* This matches the logic in getIsShowingMobileBottomNav selector since we only want to show these
     * when the mobile bottom nav is hidden */
    isShowingInlineNavLinksWhenAvailable: !getIsTabletOrSmallerSize(state),
    isHidingPersistentSearchForPageOnTablet:
      getIsHidingPersistentHeaderSearchForPageOnTablet(state),
    hasVisibleLabel: ownProps.hasVisibleLabel,
    backToSiteLinkAndText: getBackToSiteLinkAndText(state),
    navLinkLabels: getNavLinkLabels(state),
    customNavCtas: getCustomNavCtas(state),
    shouldLogOutOnBackToSite: getIsFeatureEnabled(
      'should_logout_on_partner_cta_click'
    )(state),
    shouldRedirectToBackToSiteURLInSameTab: getIsFeatureEnabled(
      'back_to_site_cta_redirect_in_same_tab'
    )(state),
    cobrandIdName: getCobrandId(state),
    cobrandDisplayName: getCobrandDisplayName(state),
    isAndroid: getIsAndroidDevice(state),
    isHidingTopSection: isInsideNativeApp,
    isInsideNativeApp,
    isShowingSearchInputVisibleLabel: !isInsideNativeApp,
    partialHeaderLinks: getPartialHeaderLinks(state),
    isShowingHomeownerNavLink: getIsFeatureEnabled('homeowner')(state),
    shouldAlwaysUseRouteChangeOnComeHomeLogo: getIsInsideNativeApp(state),
    disableUserProfileEditing: getIsFeatureEnabled(
      'disable_user_profile_editing'
    )(state),
    partnerUserId: getUserPartnerUserId(state),
    chapiPartnerInviteId: getChapiPartnerInviteId(state),
    topLocalAgentAdDetails: getTopLocalAgentAdDetails(state),
    isEmailOptInModalAllowedToOpen:
      getIsEmailOptInModalAllowedToOpenAndNoOtherModalOpen(state),
    hideProfileAuthElements: getIsFeatureEnabled('hide_profile_auth_ui')(state),
    isHomePageEnabled: getIsFeatureEnabled('homepage')(state),
    isYourTeamEnabled,
    isShowingComehomeLogoForPartner: getIsFeatureEnabled(
      'comehome_logo_in_partner_header'
    )(state),
    isShowingHeaderLenderCTA: getIsShowingHeaderLenderCTA(state),
    isLargerMobileHeaderLogoEnabled: getIsFeatureEnabled(
      'larger_mobile_header_logo'
    )(state),
    userContactInfo: getUserContactInfo(state),
    grantProgramHeaderNavItem:
      isGrantProgramEnabled &&
      grantProgramHeaderLinkText &&
      grantProgramHeaderLinkUrl
        ? {
            title: grantProgramHeaderLinkText,
            url: grantProgramHeaderLinkUrl,
            dataEventName: 'click_grant_header_cta',
            dataParentEventName: 'lender_cta',
            dataHcName: 'grant-program-header-link',
            nativeAppNavKey,
          }
        : null,
    isOutboundCtaEnabled: getIsFeatureEnabled('outbound_cta_links_have_tokens')(
      state
    ),
    accessToken: getAccessToken(state),
    isAlternateComehomeBrandingAndBrokerageDesignEnabled: getIsFeatureEnabled(
      'alternate_comehome_branding_and_brokerage_design'
    )(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleRouteChange: (view: View, params?: { slug: string }) => {
    switch (view) {
      case View.PROFILE: {
        dispatch(reportEvent('click_nav_profile', PARENT_EVENTS.CLICK_NAV));
        break;
      }
      case View.LOGIN: {
        dispatch(reportEvent('click_nav_login', PARENT_EVENTS.CLICK_NAV));
        break;
      }
      case View.SIGN_UP: {
        dispatch(reportEvent('click_nav_signup', PARENT_EVENTS.CLICK_NAV));
        break;
      }
      case View.SETTINGS: {
        dispatch(reportEvent('click_nav_settings', PARENT_EVENTS.CLICK_NAV));
        break;
      }
      case View.ALERTS: {
        dispatch(reportEvent('click_nav_alerts', PARENT_EVENTS.CLICK_NAV));
        break;
      }
      case View.WATCHLIST: {
        dispatch(reportEvent('click_nav_watchlist', PARENT_EVENTS.CLICK_NAV));
        break;
      }
      case View.HOMEOWNER: {
        dispatch(reportEvent('click_nav_homeowner', PARENT_EVENTS.CLICK_NAV));
        break;
      }
      case View.SEARCH: {
        dispatch(reportEvent('click_nav_buy', PARENT_EVENTS.CLICK_NAV));
        break;
      }
    }

    const slug = params?.slug;
    if (slug) {
      dispatch(routeChange({ view, params: { slug } }));
    } else {
      dispatch(routeChange({ view }));
    }
  },
  handleShowAuthModal: (page?: AuthModalPage) =>
    dispatch(authModalShow({ startingPage: page })),
  handleLogout: () => {
    dispatch(logOut());
  },
  handleLogoutAndRedirect: (redirectUrl: string) => {
    dispatch(logOutThenRedirect(redirectUrl));
  },
  handleAccountAvatarClick: () =>
    dispatch(reportEvent('click_nav_avatar', PARENT_EVENTS.CLICK_NAV)),
  handleClearConstrainedToPlace: () => {
    dispatch(searchClearResultMapMarkers());
    dispatch(searchClearConstrainedToPlace());
  },
  getUnreadAlertsCount: () => dispatch(fetchAlertsUnreadCount()),
  handleClickAutoCompleteSearchButton: (): void => {
    dispatch(reportClickSearchMapAutoCompleteSearchButton());
  },
  reportAppleAppStoreClick: () => {
    dispatch(reportEvent('click_app_global_nav_download_ios'));
  },
  reportGooglePlayStoreClick: () => {
    dispatch(reportEvent('click_app_global_nav_download_android'));
  },
  handleReportClickLenderNavBackToSite: () => {
    dispatch(reportClickLenderNavBackToSite());
  },
  handleReportClickLenderNavLink1: () => {
    dispatch(reportClickLenderNavLink1());
  },
  handleReportClickLenderNavLink2: () => {
    dispatch(reportClickLenderNavLink2());
  },
  handleReportClickLenderNavLink3: () => {
    dispatch(reportClickLenderNavLink3());
  },
  handleOpenModal: (modalKey: ModalKey) => {
    dispatch(openModal(modalKey));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCobranded);
