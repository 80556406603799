import HC_CONSTANTS from '@client/app.config';
import {
  View,
  VIEW_PATH_PARAM_KEYS,
  VIEW_PATHS,
} from '@client/routes/constants';
import {
  ACTIVE_LISTING_STATUSES,
  MLS_IDS_REQUIRING_BROKER_LINK_IN_FOOTER,
  MLS_IDS_REQUIRING_EXPLICIT_LISTING_STATUS_LABELS,
  PROPERTY_STATUSES,
  TRANSFER_TYPES,
} from '@client/store/constants';
import {
  AddressReference,
  Comp,
  MlsState,
  MlsStateGroup,
  PlaceReference,
  Property,
  Transfer,
} from '@client/store/sagas/queries/types';
import { AlertPropertyDetails } from '@client/store/types/alerts';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { ClaimedHomeData } from '@client/store/types/homeowner';
import {
  FilteredAddressReference,
  FilteredPlaceReference,
} from '@client/store/types/place-search';
import {
  AgentInfo,
  CommonPropertyDetails,
  IncomingPropertyLookupData,
  isInitOrFullPropertyLookupData,
  NormalizedProperty,
  PartialCompSummary,
  PartialPropertySummary,
  PartialPropertyWithAddressAndGeoRequired,
  PropertyLookupWithAddressRequired,
} from '@client/store/types/property';
import { streetAddressFormatter } from '@client/utils/formatter.utils';
import { pluralize } from '@client/utils/string.utils';
import { PartialDeep } from 'type-fest';

const DEFAULT_STATUS = PROPERTY_STATUSES.UNKNOWN;

/* Status labels that more accurately reflect the statuses provided by the MLS, required by some MLSs */
export const LISTING_STATUS_LABELS_EXPLICIT = {
  ACTIVE: 'Active',
  OFF_MARKET: 'Off Market',
  PENDING: 'Pending',
  CONTINGENT: 'Contingent',
};

/* Status labels that are more user friendly */
export const LISTING_STATUS_LABELS = {
  ACTIVE: 'For Sale',
  OFF_MARKET: 'Off Market',
  PENDING: 'Pending',
  CONTINGENT: 'Under Contract',
  COMING_SOON: 'Coming Soon',
};

const isPropertySummary = (
  summary?: PartialPropertySummary | PartialCompSummary
): summary is PartialPropertySummary =>
  !!(summary as PartialPropertySummary)?.geoLocation;
const isCompSummary = (
  summary?: PartialPropertySummary | PartialCompSummary
): summary is PartialCompSummary => !!(summary as PartialCompSummary)?.score;
const isClaimedHomesData = (
  propertyDetails: CommonPropertyDetails
): propertyDetails is ClaimedHomeData =>
  !!(propertyDetails as ClaimedHomeData)?.mls;

export const getIsPropertyAddressValid = (
  property: IncomingPropertyLookupData | PartialDeep<Property> | null
) => {
  return !!(
    property &&
    property.address &&
    property.address.slug &&
    property.address.streetAddress &&
    property.address.city &&
    property.address.state &&
    property.address.zipcode &&
    property.address.fullAddress
  );
};

/**
 * Given GQL response data, determine whether the property has valid address and geolocation data.  This is
 * used to fail hard early in the data loading flow so that downstream we can be sure that this required data
 * is present for simpler typing.
 */
export const isPropertyDataValid = (
  property:
    | IncomingPropertyLookupData
    | PartialDeep<Property>
    | PartialDeep<Comp>
    | null
): property is
  | PropertyLookupWithAddressRequired
  | PartialPropertyWithAddressAndGeoRequired => {
  /* Since we're using a type guard in this method, the below list must be kept in sync with the
   * PartialPropertyWithAddressAndGeoRequired type definition */
  return !!(
    getIsPropertyAddressValid(property) &&
    property &&
    property.geoLocation &&
    property.geoLocation.latitude &&
    property.geoLocation.longitude
  );
};

/** Create a common, flat property data structure for `propertyLookup` and `propertySpatialSearch` properties */
export const normalizePropertyData = (
  property:
    | PropertyLookupWithAddressRequired
    | PartialPropertyWithAddressAndGeoRequired,
  summary?: PartialPropertySummary | PartialCompSummary
): NormalizedProperty => {
  const listingStatus = getPropertyListingStatusObjectForProperty(
    property,
    summary
  );
  const latitude =
    property.geoLocation?.latitude ||
    ((isPropertySummary(summary)
      ? summary?.geoLocation?.latitude
      : summary?.latitude) as number);
  const longitude =
    property.geoLocation?.longitude ||
    ((isPropertySummary(summary)
      ? summary?.geoLocation?.longitude
      : summary?.longitude) as number);
  const mlsId = isPropertySummary(summary)
    ? summary?.hcMlsId
    : property.mls?.mlsID;

  return {
    fullStreetAddress: streetAddressFormatter(
      property.address.streetAddress,
      property.address.unit
    ),
    streetAddress: property.address.streetAddress,
    hcBuildingId: summary?.hcBuildingId ?? null,
    hcAddressId: property.address.hcAddressId,
    slug: property.address.slug,
    city: property.address.city,
    state: property.address.state,
    zipcode: property.address.zipcode,
    zipcodePlus4: property.address.zipcodePlus4 || null,
    county: property.county?.name || null,
    unit: property.address.unit || null,
    fullAddress: property.address.fullAddress,
    latitude,
    longitude,
    sqFt: (summary?.sqft || property.livingSpace?.livingArea)?.toString(),
    baths:
      (typeof summary?.baths === 'number'
        ? summary?.baths
        : summary?.baths?.summaryCount ||
          property.livingSpace?.bathrooms?.summaryCount) || null,
    beds: summary?.beds || property.livingSpace?.bedrooms?.count || null,
    propertyType:
      property.propertyType ||
      summary?.propertyType ||
      (isCompSummary(summary)
        ? summary?.fields?.propertyType?.compValue
        : null) ||
      null,
    listingOfficeName: property.latestListing?.listingOfficeName,
    lotSize: isInitOrFullPropertyLookupData(property)
      ? property.site?.area
      : null,
    value: getPropertyPrice(property, summary),
    avm:
      property.avm?.priceMean ||
      (isPropertySummary(summary) ? summary?.avm?.priceMean : null) ||
      null,
    rentalValue:
      (property.rentalAvm?.priceMean ? property.rentalAvm?.priceMean : null) ||
      (isPropertySummary(summary) ? summary?.rentalAvm?.priceMean : null) ||
      null,
    similarity: getSimilarityLabelForLevel(
      (isCompSummary(summary) ? summary?.score?.default?.level : null) ?? null
    ),
    status: listingStatus.status,
    statusLabel: getIsActiveListing(listingStatus.status)
      ? getListingStatusLabel(listingStatus.status, mlsId || null) || ' '
      : ' ',
    statusDate: isInitOrFullPropertyLookupData(property)
      ? property.latestListing?.statusDate || summary?.mlsStateDate
      : null,
    schools: isInitOrFullPropertyLookupData(property)
      ? property.schools || null
      : null,
    listPrice: listingStatus.listPrice || summary?.listPrice || null,
    dateOfSale:
      listingStatus.dateOfSale ||
      (summary?.saleDate && new Date(summary.saleDate)) ||
      null,
    dateOfListing:
      listingStatus.dateOfListing ||
      (summary?.listDate && new Date(summary.listDate)) ||
      null,
    salePrice: listingStatus.salePrice || summary?.salePrice || null,
    contactInfo: listingStatus.contactInfo,
    hcMlsId: mlsId || null,
    yearBuilt:
      property.structure?.yearBuilt ||
      (isPropertySummary(summary) ? summary?.yearBuilt || null : null),
    monthlyPaymentEstimate: property.paymentEstimate?.total,
    mlsLogoOverlay: property.mls?.regulations?.photosLogoOverlay
      ? property.mls?.regulations?.logoOverlay || null
      : null,
    tractId: isPropertySummary(summary)
      ? summary.tractId
      : isInitOrFullPropertyLookupData(property)
        ? property.block?.tractId
        : null,
  };
};

/**
 * A reusable method for getting the listing status of a property given different data sources
 * @param  {object} property - the 'property' object on the GraphQL response
 * @param  {object} summary - the 'summary' object on the GraphQL response
 * @return {string} status
 */
export const getPropertyListingStatus = (
  property:
    | PropertyLookupWithAddressRequired
    | PartialPropertyWithAddressAndGeoRequired,
  summary?: PartialPropertySummary | PartialCompSummary
): MlsState | 'NOT_LISTED' => {
  const status = summary
    ? summary.mlsState
    : property.mlsState || property.latestListing?.status;

  return status || DEFAULT_STATUS;
};

/**
 * A reusable method for getting the price of a property given different data sources and listing statuses
 * @param  {object} property - the 'property' object on the GraphQL response
 * @param  {object} summary - the 'summary' object on the GraphQL response
 * @return {number} price
 */
export const getPropertyPrice = (
  property:
    | PropertyLookupWithAddressRequired
    | PartialPropertyWithAddressAndGeoRequired,
  summary?: PartialPropertySummary | PartialCompSummary,
  fallbackToAvm: boolean = true
): number => {
  const status = getPropertyListingStatus(property, summary);
  const listPrice =
    summary?.listPrice || property.listPrice || property.latestListing?.price;
  const avm = isPropertySummary(summary)
    ? summary?.avm?.priceMean
    : property.avm?.priceMean;
  return getIsActiveListing(status) ? listPrice : fallbackToAvm ? avm : null;
};

export const getPropertyListingStatusObjectForProperty = (
  property:
    | PropertyLookupWithAddressRequired
    | PartialPropertyWithAddressAndGeoRequired,
  summary?: PartialPropertySummary | PartialCompSummary
): {
  status: MlsState | 'NOT_LISTED';
  listPrice: number | null;
  dateOfSale: Date | null;
  salePrice: number | null;
  contactInfo: AgentInfo;
  dateOfListing: Date | null;
} => {
  const summaryListDate = summary?.listDate || null;
  const status = getPropertyListingStatus(property, summary);
  const agent = isInitOrFullPropertyLookupData(property)
    ? {
        name: property.latestListing?.agentName || null,
        email: property.latestListing?.agentEmail || null,
        phone: property.latestListing?.agentPhone || null,
        licenseNumber: property.latestListing?.agentLicense || null,
      }
    : {
        name: null,
        email: null,
        phone: null,
        licenseNumber: null,
      };
  let listDateObj = property.listDate
    ? new Date(property.listDate)
    : summaryListDate
      ? new Date(summaryListDate)
      : null;

  if (
    isInitOrFullPropertyLookupData(property) &&
    property.transfers &&
    property.transfers.length &&
    property.transfers[0].eventType === TRANSFER_TYPES.PURCHASE
  ) {
    return {
      status,
      listPrice: getPropertyPrice(property, summary, false),
      dateOfSale: property.transfers[0].transferDate
        ? new Date(property.transfers[0].transferDate)
        : null,
      salePrice: property.transfers[0].transferPrice || null,
      contactInfo: agent,
      dateOfListing: listDateObj,
    };
  } else {
    return {
      status,
      listPrice: getPropertyPrice(property, summary, false),
      contactInfo: agent,
      dateOfListing: listDateObj,
      dateOfSale: null,
      salePrice: null,
    };
  }
};

export const getIsActiveListing = (status: string | null): boolean => {
  return (
    status === PROPERTY_STATUSES.ACTIVE ||
    status === PROPERTY_STATUSES.PENDING ||
    status === PROPERTY_STATUSES.CONTINGENT ||
    status === PROPERTY_STATUSES.COMING_SOON
  );
};

export const getSimilarityLabelForLevel = (
  level: number | null
): string | null => {
  switch (level) {
    case 0:
      return 'High';
    case 1:
      return 'Medium';
    case 2:
      return 'Low';
    default:
      return null;
  }
};

/**
 * Given a list of addresses, return only addresses that pass a validity test, gotten
 * from iOS team
 */
export const getValidAddressesFromPlaceSearch = (
  addresses: AddressReference[]
): FilteredAddressReference[] =>
  addresses.filter(
    (item) =>
      item &&
      item.hcAddressId &&
      item.fields &&
      item.fields.slug &&
      item.fields.latitudeLongitude &&
      item.fields.fullLine
  ) as FilteredAddressReference[];

/**
 * Given a list of places, return only addresses that pass a validity test
 */
export const getValidPlacesFromPlaceSearch = (
  places: PlaceReference[]
): FilteredPlaceReference[] =>
  places.filter(
    (item) => item && item.placeId && item.description && item.placeType
  ) as FilteredPlaceReference[];

/**
 * Given a list of transfers, return only the transfers that we want to display
 * @param  {array} transfers
 * @return {array} transfers to list in the UI
 */
export const filterSaleHistory = (
  transfers: Partial<Transfer>[] = []
): Partial<Transfer>[] => {
  return transfers.filter((transfer) => {
    return (
      // transfer.eventType === TRANSFER_TYPES.OTHER ||
      transfer.eventType === TRANSFER_TYPES.PURCHASE ||
      transfer.eventType === TRANSFER_TYPES.DISTRESSED ||
      transfer.eventType === TRANSFER_TYPES.FORECLOSURE
    );
  });
};

export const getDisplayTypeForTransferType = (type: string): string => {
  const typeMap = {
    [TRANSFER_TYPES.PURCHASE]: 'Sold',
    [TRANSFER_TYPES.DISTRESSED]: 'Distressed',
    [TRANSFER_TYPES.FORECLOSURE]: 'Distressed',
  };
  return typeMap[type];
};

/**
 * Open the PDP page in a new tab
 * @param {string} property slug
 */
export const openNewPropertyPage = (slug: string): void => {
  window.open(getPDPUrlForSlug(slug));
};

export const calcMetersToMiles = (
  meters: number,
  precision: number = 1
): number => parseFloat((meters * 0.00062137).toFixed(precision));

export const getPropertyCountDisplay = (
  count: number | null,
  maxCount: number
): string | null =>
  typeof count === 'number'
    ? count >= maxCount
      ? `${maxCount}+ Results`
      : `${count || '0'} ${pluralize('Result', count)}`
    : null;

/**
 * Check if a place page is displaying active listings
 * @param  {object} mlsState the mlsStateDate
 * @return {boolean} whether or not it is showing an active listing type
 */
export const mlsStatesIncludeActiveListings = (
  mlsStates: MlsStateGroup[]
): boolean => {
  return ACTIVE_LISTING_STATUSES.some((activeListingStatus) =>
    mlsStates.includes(activeListingStatus)
  );
};

/**
 * Given a slug, return the URL of the corresponding PDP.  Used for "sharing" functionality.
 * @param  {string} slug
 * @return {string} full URL
 */
export const getPDPUrlForSlug = (slug?: string, cobrandId?: string): string => {
  const path = getPDPUrlPathForSlug(slug);
  let hostname =
    process.env.NODE_ENV === 'test'
      ? 'test-hostname'
      : HC_CONSTANTS.COMEHOME_BRANDED_HOSTNAME;

  if (cobrandId) {
    hostname = hostname.replace('https://www', `https://${cobrandId}`);
  }
  return `${hostname}${path}`;
};

/**
 * Given a slug, returns the path component for the URL of the corresponding PDP.
 * @param  {string} slug
 * @return {string} path component of URL
 */
export const getPDPUrlPathForSlug = (slug?: string): string => {
  /* SECURITY: input to new RegExp is not dynamic */
  /* eslint-disable-next-line security/detect-non-literal-regexp */
  const pathSlugRegex = new RegExp(
    VIEW_PATH_PARAM_KEYS[View.PROPERTY_DETAILS]!
  );

  if (typeof slug !== 'string') {
    throw new Error(
      `getPDPUrlPathForSlug method expects a string 'slug' param, you passed ${slug}`
    );
  }

  return `${VIEW_PATHS[View.PROPERTY_DETAILS]}`.replace(pathSlugRegex, slug);
};

const isNormalizedProperty = (
  propertyDetails: NormalizedProperty | AlertPropertyDetails
): propertyDetails is NormalizedProperty => {
  /* Can check any field that's in NormalizedProperty, but not in AlertPropertyDetails */
  return !!(propertyDetails as NormalizedProperty).avm;
};

/**
 * Return a value and label based upon a property's available price data and property status
 */
export const getPropertyValueAndValueLabel = (
  propertyDetails: AlertPropertyDetails | NormalizedProperty
): {
  value: number | null;
  valueLabel: string;
} => {
  return propertyDetails.status &&
    getIsActiveListing(propertyDetails.status) &&
    propertyDetails.listPrice
    ? /* If is an active listing and we have a list price, show the list price */
      {
        value: propertyDetails.listPrice,
        /* Using a type assertion here since we know that, due to the status being as active listing status, this'll return a string */
        valueLabel: getListingStatusLabel(
          propertyDetails.status,
          propertyDetails.hcMlsId
        ) as string,
      }
    : /* If is an active listing and we don't have the list price, show as active with a placeholder */
      propertyDetails.status &&
        getIsActiveListing(propertyDetails.status) &&
        !propertyDetails.listPrice
      ? {
          value: null,
          /* Using a type assertion here since we know that, due to the status being as active listing status, this'll return a string */
          valueLabel: getListingStatusLabel(
            propertyDetails.status,
            propertyDetails.hcMlsId
          ) as string,
        }
      : /* If is off-market, show AVM */
        isNormalizedProperty(propertyDetails) && propertyDetails.avm
        ? {
            value: propertyDetails.avm,
            valueLabel: 'Off Market',
          }
        : /* If has no AVM, show last sold price */
          propertyDetails.salePrice
          ? {
              value: propertyDetails.salePrice,
              valueLabel: 'Sold',
            }
          : /* If has no last sold price, then just show a placeholder */
            {
              value: null,
              valueLabel: 'Off Market',
            };
};

export const getMLSIdFromPropertyDetails = (
  propertyDetails: CommonPropertyDetails
): number | null => {
  if (propertyDetails) {
    const mlsId =
      (isClaimedHomesData(propertyDetails)
        ? propertyDetails?.mls?.mlsID
        : propertyDetails?.hcMlsId) || null;
    /* enforce hcMlsId into number for consistency */
    return typeof mlsId === 'string' ? parseInt(mlsId, 10) : mlsId;
  } else {
    return null;
  }
};

/**
 * Given property-graph address data, return an object containing address data to send with analytics events
 */
export const getAnalyticsAddressForProperty = (
  address: PropertyLookupWithAddressRequired['address'] | null
): AnalyticsEventAddress | null => {
  if (address) {
    return {
      street: address?.streetAddress,
      unit: address.unit,
      city: address.city,
      state: address.state,
      zip: address.zipcode,
      slug: address.slug,
      address_id: address.hcAddressId,
    };
  } else {
    return null;
  }
};

export const getShowBrokerLinkForMLSId = (mlsId?: number | null): boolean => {
  return mlsId
    ? MLS_IDS_REQUIRING_BROKER_LINK_IN_FOOTER.includes(mlsId)
    : false;
};

export const getListingStatusLabel = (
  status: MlsState | 'NOT_LISTED',
  mlsId: number | null
) => {
  return mlsId &&
    MLS_IDS_REQUIRING_EXPLICIT_LISTING_STATUS_LABELS.includes(mlsId)
    ? (LISTING_STATUS_LABELS_EXPLICIT[status] as string | undefined)
    : (LISTING_STATUS_LABELS[status] as string | undefined);
};

export const getColorForFinanceDisplay = (value, loanAmount) => {
  if (!value || !loanAmount) {
    return '#EC770D'; /* orange */
  } else if (value - loanAmount > 0) {
    return '#00BD6C'; /* green */
  } else {
    return '#B10234'; /* red */
  }
};
