import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authModalShow } from '@client/store/actions/auth.actions';
import {
  getIsLoggedIn,
  getUserEmailAddress,
  getUserPhoneNumber,
} from '@client/store/selectors/auth.selectors';
import { getGenericLOInfo } from '@client/store/selectors/cobranding.selectors';
import { getLoanOfficerInfo } from '@client/store/selectors/loan-officer.selectors';
import {
  selectIsRequestATourModalOpen,
  setIsModalOpen,
} from '@client/store/slices/request-a-tour.slice';
import { RequestATourFormState } from '@client/store/types/request-a-tour';

/* Used to display Request a Tour modal */
export function useRequestATourState() {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const userPhoneNumber = useSelector(getUserPhoneNumber);
  const userEmail = useSelector(getUserEmailAddress);
  const isRequestATourModalOpen = useSelector(selectIsRequestATourModalOpen);
  const loanOfficerInfo = useSelector(getLoanOfficerInfo);
  const genericLoanOfficerInfo = useSelector(getGenericLOInfo);

  const defaultState: RequestATourFormState = {
    contactType: 'phone',
    phone: userPhoneNumber || '',
    email: userEmail || '',
    tourType: null,
    preferredTime: null,
    contactForFinancing: false,
    financingContactEmail:
      loanOfficerInfo?.email || genericLoanOfficerInfo?.email || null,
  };
  const [requestATourFormState, setRequestATourFormState] =
    useState<RequestATourFormState>(defaultState);

  return {
    isRequestATourModalOpen,
    openRequestATourModal: () => {
      if (isUserLoggedIn) {
        dispatch(setIsModalOpen({ isModalOpen: true }));
      } else {
        dispatch(
          authModalShow({
            startingPage: 'sign-up',
            headingText: {
              'sign-up': 'Tour this home',
              login: 'Tour this home',
            },
            afterAuthAction: setIsModalOpen({ isModalOpen: true }),
          })
        );
      }
    },
    closeRequestATourModal: () =>
      dispatch(setIsModalOpen({ isModalOpen: false })),
    requestATourFormState,
    setRequestATourFormState,
    resetRequestATourFormState: () => setRequestATourFormState(defaultState),
  };
}
