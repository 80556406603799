import { HeaderProps } from '@client/store/types/cobranded-components/header';
import classNames from 'classnames';
import React from 'react';
import AuthButton from '../AuthButton';
import ClickableLogo from '../ClickableLogo';
import ComeHomeHeaderLogoByHouseCanaryCobranded from '../ComeHomeHeaderLogo/ComeHomeHeaderLogoByHouseCanaryCobranded';
import ComeHomeHeaderLogoCobranded from '../ComeHomeHeaderLogo/ComeHomeHeaderLogoCobranded';
import PillButton from '../generic/PillButton';

type ComponentProp = {
  localShowAuthModal: () => void;
};
type Props = HeaderProps & ComponentProp;

export const HeaderAuth: React.FC<Props> = (props) => {
  const { hideProfileAuthElements, theme, isLoggedIn, localShowAuthModal } =
    props;

  return (
    <>
      {!hideProfileAuthElements && (
        <div
          className={classNames(
            theme.DesktopHeaderTopRowRightContainer,
            theme.AuthButtonWrapper
          )}
          data-hc-name={isLoggedIn ? 'profile-button' : 'join-login-link'}
        >
          {/* Only displayed on large screens via CSS */}
          {isLoggedIn ? (
            <div className={theme.DesktopAuthButtonContainer}>
              <AuthButton {...props} />
            </div>
          ) : (
            <PillButton
              dataHcName={'join-login-button'}
              ariaLabel="Join or Log In"
              onClick={localShowAuthModal}
              theme={theme}
              className={theme.ApplyNowButton}
            >
              Join or Log In
            </PillButton>
          )}
        </div>
      )}
      <ClickableLogo
        shouldShowComehomeByHouseCanaryForWells
        dataHcName={'comehome-logo'}
        Logo={ComeHomeHeaderLogoCobranded}
        LogoByHouseCanary={ComeHomeHeaderLogoByHouseCanaryCobranded}
        theme={theme}
      />
    </>
  );
};
