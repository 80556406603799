import { createSelector } from 'reselect';

import {
  LEGACY_SEARCH_SIDEBAR_MAX_RESULTS,
  SEARCH_SIDEBAR_MAX_RESULTS,
} from '@client/store/constants';
import { FeatureId } from '@client/store/types/feature-flags';
import { ReduxState } from '@client/store/types/redux-state';

export const getEnabledFeatureList = (state: ReduxState) =>
  state.enabledFeatures;

export const getIsFeatureEnabled = (featureId: FeatureId) =>
  createSelector(
    getEnabledFeatureList,
    (enabledFeatures) => enabledFeatures.indexOf(featureId) > -1
  );

export const getSearchListMaxResultCount = createSelector(
  getEnabledFeatureList,
  (enabledFeatures) =>
    enabledFeatures.includes('search_count_request')
      ? LEGACY_SEARCH_SIDEBAR_MAX_RESULTS
      : SEARCH_SIDEBAR_MAX_RESULTS
);

export const getIsPhotoLoginUpsell = createSelector(
  getEnabledFeatureList,
  (enabledFeatures) => enabledFeatures.indexOf('photo_login_upsell') > -1
);

export const getHideForecastBlockPdp = createSelector(
  getEnabledFeatureList,
  (enabledFeatures) => enabledFeatures.indexOf('hide_forecast_block_pdp') > -1
);

export const getIsDisplayMultiFamilySearchFiltersEnabled = createSelector(
  getEnabledFeatureList,
  (enabledFeatures) =>
    enabledFeatures.indexOf('temp_display_multi_family_search_filters') > -1
);

export const getIsShowComingSoonInListingStatusFilterEnabled = createSelector(
  getEnabledFeatureList,
  (enabledFeatures) =>
    enabledFeatures.indexOf('temp_show_coming_soon_in_listing_status_filter') >
    -1
);
