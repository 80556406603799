import logoSrc from '@client/assets/images/powered-by-comehome.svg';
import React from 'react';

/**
 * An alternate logo for display when required by a specific MLS
 */
const HeaderLogoByHouseCanaryDarker: React.FunctionComponent<
  React.HTMLAttributes<HTMLImageElement>
> = (props) => {
  return <img src={logoSrc} alt={'ComeHome by HouseCanary Logo'} {...props} />;
};

export default HeaderLogoByHouseCanaryDarker;
