import PoweredByComeHomeStackedLogoSvg from '@client/assets/images/powered-by-comehome-stacked.svg';
import ComeHomeLogoVariableSize from '@client/assets/images/powered-by-comehome.svg';
import React from 'react';

type Props = {
  useStackedLogo?: boolean;
};

const ComeHomeLogoVariableSizeImg: React.FunctionComponent<
  Props & React.HTMLAttributes<HTMLImageElement>
> = ({ useStackedLogo, ...rest }) => {
  return (
    <img
      src={
        useStackedLogo
          ? PoweredByComeHomeStackedLogoSvg
          : ComeHomeLogoVariableSize
      }
      alt={'ComeHome Logo'}
      {...rest}
    />
  );
};

export default ComeHomeLogoVariableSizeImg;
