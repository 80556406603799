import PoweredByComeHomeStackedLogoSvg from '@client/assets/images/powered-by-comehome-stacked.svg';
import logoSrc from '@client/assets/images/powered-by-comehome.svg';
import React from 'react';

type Props = {
  useStackedLogo?: boolean;
};

/**
 * An alternate logo for display when required by a specific MLS
 */
const ComehomeHeaderLogoWithHouse: React.FunctionComponent<
  Props & React.HTMLAttributes<HTMLImageElement>
> = ({ useStackedLogo, ...rest }) => {
  return (
    <img
      src={useStackedLogo ? PoweredByComeHomeStackedLogoSvg : logoSrc}
      alt={'ComeHome Logo'}
      {...rest}
    />
  );
};

export default ComehomeHeaderLogoWithHouse;
