import { themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React, { Component, ReactElement } from 'react';

import ClickableLogo from '@client/components/ClickableLogo';
import AuthButtonComehome from '@client/components/cobrand/comehome/AuthButtonComehome';
import CobrandedStyles from '@client/components/CobrandedStyles';
import ComehomeHeaderLogoByHousecanaryWithHouse from '@client/components/ComehomeHeaderLogoByHousecanaryWithHouse';
import ComeHomeLogoImg from '@client/components/ComeHomeLogoImg';
import DesktopNavItem from '@client/components/DesktopNavItem';
import {
  AccountMenuItem,
  getMenuItemsLoggedIn,
  getMenuItemsLoggedOut,
  isExternalUrlItem,
  isModalItem,
  isNavItem,
} from '@client/components/Header/HeaderCobranded';
import HeaderMobileBottomSection from '@client/components/HeaderMobileBottomSection';
import HeaderMobileSearchHomesNav from '@client/components/HeaderMobileSearchHomesNav';
import MenuIcon from '@client/components/MenuIcon';
import MenuNotificationCount from '@client/components/MenuNotificationCount';
import AutoCompleteSearchContainer from '@client/containers/autocomplete-search.container';
import HeaderBackArrowContainer from '@client/containers/header-back-arrow.container';
import defaultTheme from '@client/css-modules/HeaderComehome.css';
import CloseIcon from '@client/inline-svgs/close';
import SearchIcon from '@client/inline-svgs/search';
import { HOMEOWNER_BREAKOUT_ROUTES, View } from '@client/routes/constants';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import { HeaderProps } from '@client/store/types/cobranded-components/header';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import {
  disableBodyScroll,
  enableBodyScroll,
} from '@client/utils/body-scroll-lock';
import { AnimatePresence, motion } from 'framer-motion';

const SEARCH_MENU = 'search';
const ACCOUNT_MENU = 'account';

type State = {
  isMobileMenuOpen: boolean;
  mobileMenuType: MenuType;
  isMounted: boolean;
  isShowingSearchFieldOnDesktop: boolean;
};
type MenuType = typeof SEARCH_MENU | typeof ACCOUNT_MENU | null;

/**
 * Due to SSR requirements, the desktop vs. mobile experiences are shown/hidden via CSS.
 * Each is grouped by a top-level container in the render method.
 */
export class HeaderComehome extends Component<HeaderProps, State> {
  mobileAccountMenuEle: HTMLDivElement | null = null;
  aboveMobileAccountMenuEle: HTMLDivElement | null = null;

  state: State = {
    isMobileMenuOpen: false,
    isMounted: false,
    mobileMenuType: null,
    isShowingSearchFieldOnDesktop: false,
  };

  componentDidMount() {
    const { isLoggedIn } = this.props;
    /* Only attempt alerts fetch if logged in, otherwise we display a message on the page */
    if (isLoggedIn) {
      this.fetchAlertsData();
    }
    this.setState({ isMounted: true });
  }

  componentDidUpdate(prevProps: HeaderProps) {
    const { currentView, isLoggedIn } = this.props;

    /* When logging in/signing up FROM the alerts page, fetch alerts after authenticating */
    if (isLoggedIn && !prevProps.isLoggedIn) {
      this.fetchAlertsData();
    }

    /** close hamburger menu on view change */
    if (currentView !== prevProps.currentView) {
      this.closeMobileMenu();
    }
  }

  fetchAlertsData(): void {
    const { getUnreadAlertsCount, userId } = this.props;

    if (userId) {
      getUnreadAlertsCount();
    }
  }

  toggleAccountMenu = (): void => {
    const isAccountMenu = this.state.mobileMenuType === ACCOUNT_MENU;
    if (isAccountMenu && this.mobileAccountMenuEle) {
      enableBodyScroll(this.mobileAccountMenuEle);
    }

    this.toggleMobileMenu(
      this.state.mobileMenuType !== ACCOUNT_MENU ? ACCOUNT_MENU : null
    );
  };

  toggleSearchMenu = (): void => {
    this.toggleMobileMenu(
      this.state.mobileMenuType !== SEARCH_MENU ? SEARCH_MENU : null
    );
  };

  toggleMobileMenu = (newMenuType: MenuType): void => {
    const { isMobileMenuOpen } = this.state;
    this.setState({
      isMobileMenuOpen: !isMobileMenuOpen,
      mobileMenuType: newMenuType,
    });
  };

  closeMobileMenu = (): void => {
    if (this.mobileAccountMenuEle) {
      enableBodyScroll(this.mobileAccountMenuEle);
    }
    this.setState({ isMobileMenuOpen: false, mobileMenuType: null });
  };

  localShowAuthModal = (): void => {
    this.setState({ isMobileMenuOpen: false });
    this.props.handleShowAuthModal();
  };

  onAddressSearchResultClick = (): void => {
    if (this.props.shouldCloseMenuOnAddressSearchResultClick) {
      this.closeMobileMenu();
    }
    this.closeSearchOnDesktop();
  };

  handleSignUp = () => {
    this.closeMobileMenu();
    this.props.handleShowAuthModal();
  };

  handleLogout = () => {
    this.closeMobileMenu();
    this.props.handleLogout();
  };

  handleClickScreen = () => {
    this.closeMobileMenu();
  };

  handleClearEmptyInput = () => {
    this.closeMobileMenu();
  };

  handleClearPopulatedInput = () => {
    const {
      handleClearConstrainedToPlace,
      shouldClearConstrainedPlaceOnClearingSearch,
    } = this.props;
    /* Clear the place boundary on the map and send subsequent property requests without a
     * place constraint */
    if (shouldClearConstrainedPlaceOnClearingSearch) {
      handleClearConstrainedToPlace();
    }
  };

  getMenuItems = (): AccountMenuItem[] => {
    if (this.props.isLoggedIn) {
      return getMenuItemsLoggedIn(this.props);
    } else {
      return getMenuItemsLoggedOut(this.props);
    }
  };

  handleAccountMenuOnClick = (menuItem: AccountMenuItem): void => {
    if (isModalItem(menuItem)) {
      this.closeMobileMenu();
      this.props.handleOpenModal(menuItem.modalKey);
    } else if (isNavItem(menuItem)) {
      if (menuItem.key === View.LOGOUT) {
        this.handleLogout();
      } else if (menuItem.route) {
        this.closeMobileMenu();

        if (menuItem.route === View.SIGN_UP || menuItem.route === View.LOGIN) {
          // for SIGN_UP/LOGIN routes, show the modal instead of the actual route in this context
          const startingPage =
            menuItem.route === View.SIGN_UP
              ? AUTH_MODAL_PAGES.SIGN_UP
              : AUTH_MODAL_PAGES.LOGIN;
          this.props.handleShowAuthModal(startingPage);
        } else {
          this.props.handleRouteChange(menuItem.route);
        }
      }
    } else if (isExternalUrlItem(menuItem)) {
      window.open(menuItem.url, '_blank');
    }
  };

  createAccountMenu = (menuItem: AccountMenuItem): ReactElement => {
    const { theme, currentView } = this.props;
    return (
      <div
        className={classNames(theme.MobileMenuListItem, {
          [theme.MobileMenuListItemSelected]:
            isNavItem(menuItem) && currentView === menuItem.route,
        })}
        data-hc-name={menuItem.dataHcName}
        data-event-name={menuItem.dataEventName}
        data-parent-event-name={menuItem.dataParentEventName}
        key={menuItem.title}
        onClick={() => this.handleAccountMenuOnClick(menuItem)}
      >
        {menuItem.title}
      </div>
    );
  };

  toggleSearchOnDesktop = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.stopPropagation();
    this.setState((prevState) => {
      return {
        isShowingSearchFieldOnDesktop: !prevState.isShowingSearchFieldOnDesktop,
      };
    });
  };

  handleDesktopClickScreen = (e: React.MouseEvent): void => {
    e.stopPropagation();
    this.closeSearchOnDesktop();
  };

  closeSearchOnDesktop = (): void => {
    this.setState({ isShowingSearchFieldOnDesktop: false });
  };

  setMobileAdjustedHeight = (ele) => {
    if (ele) {
      const { top } = ele.getBoundingClientRect();
      ele.style.height = `${window.innerHeight - top}px`;
    }
  };

  registerEleAndDisableBodyScroll = (ele: HTMLDivElement | null) => {
    if (ele) {
      this.mobileAccountMenuEle = ele;
      disableBodyScroll(this.mobileAccountMenuEle);
    }
  };

  registerAboveMobileAccountMenuContainer = (ele: HTMLDivElement | null) => {
    this.aboveMobileAccountMenuEle = ele;
  };

  render() {
    const {
      theme,
      isShowingSearchIconForRoute,
      isLoggedIn,
      isShowingSearchFieldInsideHeader,
      isShowingPersistentSearchFieldBelowHeader,
      shouldDisplayCurrentLocationSearchOption,
      placeGeoJSONDescription,
      isDesktopHeaderFullWidth,
      handleRouteChange,
      unreadAlertsCount,
      currentView,
      isShowingInlineNavLinksWhenAvailable,
      isHidingPersistentSearchForPageOnTablet,
      isShowingBackNavButton,
      isHidingTopSection,
      isShowingSearchInputVisibleLabel,
      isShowingHomeownerNavLink,
      isYourTeamEnabled,
      isReferralServicesEnabled,
      userContactInfo,
      nativeAppLenderCTAClick,
      grantProgramHeaderNavItem,
      isInsideNativeApp,
      navLinkLabels,
    } = this.props;

    const {
      isMobileMenuOpen,
      isMounted,
      mobileMenuType,
      isShowingSearchFieldOnDesktop,
    } = this.state;
    const isAccountMenuOpen = mobileMenuType === ACCOUNT_MENU;
    const isSearchMenuOpen =
      isShowingPersistentSearchFieldBelowHeader ||
      (isMobileMenuOpen && mobileMenuType === SEARCH_MENU);

    return (
      <>
        {/* Only displayed on small screens via CSS */}
        <div className={theme.MobileContainer}>
          {!isHidingTopSection && (
            <div
              className={theme.MobileTopSection}
              ref={this.registerAboveMobileAccountMenuContainer}
            >
              {isShowingBackNavButton ? (
                <HeaderBackArrowContainer />
              ) : (
                <MenuIcon
                  ariaLabel={isAccountMenuOpen ? 'Close menu' : 'Open menu'}
                  handleClick={this.toggleAccountMenu}
                  active={isAccountMenuOpen}
                  theme={theme}
                />
              )}
              <div className={theme.MobileLogoSearchContainer}>
                {isShowingSearchIconForRoute && !isMobileMenuOpen && (
                  <div className={theme.MobileSearchIconContainer}>
                    <button
                      className={theme.SearchIconButton}
                      type="button"
                      aria-label="Search"
                      aria-expanded={false}
                      data-hc-name="search-icon-button"
                      onKeyDown={onEnterOrSpaceKey(this.toggleSearchMenu)}
                      onClick={this.toggleSearchMenu}
                    >
                      <SearchIcon className={theme.SearchIcon} />
                    </button>
                  </div>
                )}
                <div className={theme.MobileLogoContainer}>
                  <ClickableLogo
                    dataHcName={'comehome-logo'}
                    ariaLabel="ComeHome logo"
                    Logo={ComeHomeLogoImg}
                    LogoByHouseCanary={ComehomeHeaderLogoByHousecanaryWithHouse}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={theme.MobileMenuContainer}>
            <AnimatePresence>
              {isSearchMenuOpen && (
                <motion.nav
                  initial={{ height: 0, opacity: 0 }}
                  exit={{
                    height: 0,
                    opacity: 0,
                    transition: {
                      opacity: {
                        duration: 0.1,
                      },
                      height: {
                        duration: 0.2,
                      },
                    },
                  }}
                  animate={{
                    opacity: 1,
                    height: 'initial',
                    transition: {
                      ease: 'easeInOut',
                      duration: isSearchMenuOpen ? 0.1 : 0.2,
                    },
                  }}
                  key="menu"
                  aria-label="Navigation menu"
                  className={classNames(theme.MobileSearchItemAnimation, {
                    [theme.MobileSearchItemAnimationHiddenOnTablet]:
                      isHidingPersistentSearchForPageOnTablet,
                  })}
                >
                  <ul className={theme.MobileMenuList}>
                    <li key={'SearchContainer'} className={theme.SearchItem}>
                      {/* Only focus autocomplete input on mount when not logged in,
                      otherwise saved searches would automatically be shown, blocking other menu items */}
                      <AutoCompleteSearchContainer
                        /* Only focus the input on mount if it's not persistently shown and if the user is logged-out
                         * since otherwise the saved searches will be displayed which might be jarring */
                        focusInputOnMount={
                          !isShowingPersistentSearchFieldBelowHeader &&
                          !isLoggedIn
                        }
                        theme={theme}
                        data-hc-name="search-field"
                        onResultClick={this.onAddressSearchResultClick}
                        prefilledUserInput={placeGeoJSONDescription}
                        handleClearPopulatedInput={
                          this.handleClearPopulatedInput
                        }
                        handleClearEmptyInput={this.handleClearEmptyInput}
                        shouldDisplayCurrentLocationOption={
                          shouldDisplayCurrentLocationSearchOption
                        }
                        hasVisibleLabel={isShowingSearchInputVisibleLabel}
                      />
                    </li>
                  </ul>
                </motion.nav>
              )}
              {!!(isAccountMenuOpen && this.aboveMobileAccountMenuEle) && (
                <motion.nav
                  initial={{ x: '-100%' }}
                  exit={{ x: '-100%' }}
                  animate={{
                    x: '0%',
                    transition: {
                      ease: 'easeInOut',
                      duration: isAccountMenuOpen ? 0.1 : 0.2,
                      damping: 0,
                    },
                  }}
                  key="account-menu"
                  style={{
                    top: this.aboveMobileAccountMenuEle.getBoundingClientRect()
                      .height,
                  }}
                  className={theme.MobileAccountMenuAnimation}
                >
                  <div
                    className={theme.MobileAccountMenuContainer}
                    ref={this.setMobileAdjustedHeight}
                  >
                    <div
                      className={theme.MobileAccountMenuContainerNavItems}
                      ref={this.registerEleAndDisableBodyScroll}
                    >
                      {this.getMenuItems().map((menuItem: AccountMenuItem) => {
                        return this.createAccountMenu(menuItem);
                      })}
                    </div>
                  </div>
                </motion.nav>
              )}
            </AnimatePresence>
            {!!(isAccountMenuOpen && this.aboveMobileAccountMenuEle) && (
              <div
                style={{
                  top: this.aboveMobileAccountMenuEle.getBoundingClientRect()
                    .height,
                }}
                className={theme.MobileAccountMenuBackdrop}
                onClick={this.closeMobileMenu}
              />
            )}
            {isSearchMenuOpen && !isShowingPersistentSearchFieldBelowHeader && (
              <div
                className={theme.MobileMenuScreen}
                onClick={this.handleClickScreen}
              />
            )}
            {currentView &&
              !HOMEOWNER_BREAKOUT_ROUTES.includes(currentView) && (
                <HeaderMobileBottomSection
                  isShowingBackNavButton={isShowingBackNavButton}
                  currentView={currentView}
                  theme={theme}
                />
              )}
            <HeaderMobileSearchHomesNav theme={theme} />
          </div>
        </div>

        {/* Only displayed on large screens via CSS */}
        <div className={theme.DesktopContainer}>
          <div
            data-hc-name={'top-section'}
            className={classNames(theme.DesktopHeaderTopRow, {
              [theme.DesktopInnerContainerFullWidth]: isDesktopHeaderFullWidth,
            })}
          >
            {isShowingBackNavButton ? (
              <HeaderBackArrowContainer />
            ) : (
              <div data-hc-name={'logo'} className={theme.DesktopLogoContainer}>
                <ClickableLogo
                  dataHcName={'comehome-logo'}
                  Logo={ComeHomeLogoImg}
                  LogoByHouseCanary={ComehomeHeaderLogoByHousecanaryWithHouse}
                  theme={theme}
                />
              </div>
            )}
            <div
              className={classNames(theme.DesktopRightContainer, {
                [theme.WithBackArrow]: isShowingBackNavButton,
                [theme.DesktopRightContainerWithSearchField]:
                  isShowingSearchFieldInsideHeader && isMounted,
                [theme.DesktopRightContainerWithSearchFieldShowing]:
                  isShowingSearchFieldOnDesktop,
              })}
            >
              <div
                data-hc-name={'links'}
                className={theme.DesktopHeaderBottomRowLeftContainer}
              >
                <CobrandedStyles>
                  {({ activeNavItemColor }) => (
                    <>
                      {isShowingInlineNavLinksWhenAvailable &&
                        !isShowingBackNavButton && (
                          <div className={theme.DesktopNavItemContainer}>
                            <DesktopNavItem
                              dataHcName={'buy-home-button'}
                              theme={theme}
                              view={View.SEARCH}
                              currentView={currentView}
                              activeColor={activeNavItemColor}
                              hasActiveColorWhenHovered
                              onClick={() => handleRouteChange(View.SEARCH)}
                            >
                              {navLinkLabels.navLinkSearchLabel ||
                                'Find a home'}
                            </DesktopNavItem>
                            {isShowingHomeownerNavLink && (
                              <DesktopNavItem
                                dataHcName={'my-home-button'}
                                dataTooltipAnchorId={'my-home'}
                                theme={theme}
                                view={[
                                  View.HOMEOWNER,
                                  View.HOMEOWNER_CLAIMED_HOMES,
                                  View.HOMEOWNER_PROPERTY_DETAILS,
                                ]}
                                currentView={currentView}
                                activeColor={activeNavItemColor}
                                hasActiveColorWhenHovered
                                onClick={() =>
                                  handleRouteChange(View.HOMEOWNER)
                                }
                              >
                                {navLinkLabels.navLinkHomeownerLabel ||
                                  'My home'}
                              </DesktopNavItem>
                            )}
                            <DesktopNavItem
                              theme={theme}
                              dataHcName={'saved-button'}
                              view={[View.WATCHLIST, View.SAVED_SEARCHES]}
                              currentView={currentView}
                              activeColor={activeNavItemColor}
                              hasActiveColorWhenHovered
                              onClick={() => handleRouteChange(View.WATCHLIST)}
                            >
                              {navLinkLabels.navLinkWatchlistLabel || 'Saved'}
                            </DesktopNavItem>
                            <DesktopNavItem
                              theme={theme}
                              dataHcName={'alerts-button'}
                              view={View.ALERTS}
                              currentView={currentView}
                              activeColor={activeNavItemColor}
                              hasActiveColorWhenHovered
                              onClick={() => handleRouteChange(View.ALERTS)}
                            >
                              {navLinkLabels.navLinkAlertsLabel || 'Alerts'}
                              <MenuNotificationCount
                                theme={theme}
                                count={unreadAlertsCount}
                              />
                            </DesktopNavItem>
                            {isYourTeamEnabled && isReferralServicesEnabled && (
                              <DesktopNavItem
                                theme={theme}
                                dataHcName={'find-an-agent-button'}
                                dataEventName={EVENTS.CLICK_FIND_AGENT_HEADER}
                                dataParentEventName={PARENT_EVENTS.CLICK_NAV}
                                dataEventDataJson={userContactInfo}
                                view={View.CONCIERGE_TEAM}
                                currentView={currentView}
                                onClick={() =>
                                  handleRouteChange(View.CONCIERGE_TEAM)
                                }
                                activeColor={activeNavItemColor}
                                hasActiveColorWhenHovered
                              >
                                Find an agent
                              </DesktopNavItem>
                            )}
                            {grantProgramHeaderNavItem && (
                              <DesktopNavItem
                                view={null}
                                theme={theme}
                                dataHcName={
                                  grantProgramHeaderNavItem.dataHcName
                                }
                                dataEventName={
                                  grantProgramHeaderNavItem.dataEventName!
                                }
                                dataParentEventName={
                                  grantProgramHeaderNavItem.dataParentEventName!
                                }
                                currentView={currentView}
                                onClick={() => {
                                  if (
                                    isInsideNativeApp &&
                                    grantProgramHeaderNavItem.nativeAppNavKey
                                  ) {
                                    nativeAppLenderCTAClick(
                                      grantProgramHeaderNavItem.nativeAppNavKey,
                                      {}
                                    );
                                  } else {
                                    const url = grantProgramHeaderNavItem.url;
                                    url &&
                                      window.open(
                                        grantProgramHeaderNavItem?.url,
                                        '_blank'
                                      );
                                  }
                                }}
                                activeColor={activeNavItemColor}
                                hasActiveColorWhenHovered
                              >
                                {grantProgramHeaderNavItem.title}
                              </DesktopNavItem>
                            )}
                          </div>
                        )}
                    </>
                  )}
                </CobrandedStyles>
                {isShowingSearchFieldOnDesktop &&
                  isShowingSearchIconForRoute && (
                    <AutoCompleteSearchContainer
                      /* Only focus the input on mount if it's not persistently shown and if the user is logged-out
                       * since otherwise the saved searches will be displayed which might be jarring */
                      focusInputOnMount={isMounted && !isLoggedIn}
                      theme={theme}
                      hideSearchButton
                      onResultClick={this.onAddressSearchResultClick}
                      prefilledUserInput={placeGeoJSONDescription}
                      handleClearPopulatedInput={this.handleClearPopulatedInput}
                      handleClearEmptyInput={this.handleClearEmptyInput}
                      shouldDisplayCurrentLocationOption={
                        shouldDisplayCurrentLocationSearchOption
                      }
                    />
                  )}
                {isShowingSearchIconForRoute && (
                  <button
                    aria-expanded={isShowingSearchFieldOnDesktop}
                    type="button"
                    aria-label="Search for a city, ZIP code or address"
                    onKeyDown={onEnterOrSpaceKey(this.toggleSearchOnDesktop)}
                    onClick={this.toggleSearchOnDesktop}
                    data-hc-name="search-expand-button"
                    className={theme.DesktopBottomRowIconElement}
                  >
                    {isShowingSearchFieldOnDesktop ? (
                      <CloseIcon className={theme.SearchCloseIcon} />
                    ) : (
                      <SearchIcon className={theme.SearchIcon} />
                    )}
                  </button>
                )}
                {/* Only displayed on large screens via CSS */}
                <div
                  className={theme.DesktopAuthButtonContainer}
                  data-hc-name={
                    isLoggedIn ? 'profile-button' : 'join-login-link'
                  }
                >
                  <AuthButtonComehome {...this.props} />
                </div>
                {isShowingBackNavButton && (
                  <div
                    data-hc-name={'logo'}
                    className={classNames(
                      theme.DesktopLogoContainer,
                      theme.OnRight
                    )}
                  >
                    <ClickableLogo
                      dataHcName={'comehome-logo'}
                      Logo={ComeHomeLogoImg}
                      LogoByHouseCanary={
                        ComehomeHeaderLogoByHousecanaryWithHouse
                      }
                      theme={theme}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {isShowingSearchFieldOnDesktop && (
            <div
              className={theme.DesktopMenuScreen}
              onClick={this.handleDesktopClickScreen}
            />
          )}
        </div>
      </>
    );
  }
}

const ThemedHeader = themr('HeaderComehome', defaultTheme)(HeaderComehome);
export default ThemedHeader;
