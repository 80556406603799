import CircleButton from '@client/components/generic/CircleButton';
import defaultTheme from '@client/css-modules/IconCtaButton.css';
import { useLenderCtaUrlModifier } from '@client/hooks/lender-cta-config.hooks';
import CalculatorIcon from '@client/inline-svgs/calculator';
import { getPropertyDetailPageConfig } from '@client/store/selectors/cobranding.selectors';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';

type Props = {
  theme: Theme;
};

const IconCtaButton = ({ theme }: Props) => {
  const propertyDetailPageConfig = useSelector(getPropertyDetailPageConfig);
  const ctaText = propertyDetailPageConfig?.iconCtaText;
  const ctaUrl = propertyDetailPageConfig?.iconCtaUrl;
  const shouldDisplayButton = ctaText && ctaUrl;
  const url = useLenderCtaUrlModifier(ctaUrl);

  if (!shouldDisplayButton) {
    return null;
  }

  return (
    <div className={theme.IconCtaButton}>
      <CircleButton
        data-event-name="click_pdp_icon_cta"
        ariaLabel={ctaText}
        theme={theme}
        onClick={() => url && window.open(url, '_blank')}
      >
        <CalculatorIcon className={theme.CalculatorIcon} />
      </CircleButton>
      <div className={theme.ButtonLabel}>{ctaText}</div>
    </div>
  );
};

const ThemedIconCtaButton = themr(
  'ClickIconButton',
  defaultTheme
)(IconCtaButton);
export default ThemedIconCtaButton;
