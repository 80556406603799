import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  getIsInsideNativeApp,
  getSupportUrl,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getIsMobile,
  getIsTabletOrSmallerSize,
} from '@client/store/selectors/match-media.selectors';
import { getIsShowingBrokerLinkInFooter } from '@client/store/selectors/mls-global-display-rules.selectors';
import { getUtmState } from '@client/store/selectors/tracking.selectors';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

import Footer from '@client/components/Footer';
import { ALL_SEARCH_VIEWS, View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';

const mapStateToProps = (state) => {
  const currentView = getCurrentView(state) as View;
  const isSearchView = ALL_SEARCH_VIEWS.includes(currentView);
  const isPDPView = currentView === View.PROPERTY_DETAILS;
  const isLODirectEnabled = getIsFeatureEnabled('lo_direct')(state);

  return {
    shouldShowCondensedList: getIsMobile(state),
    currentView: getCurrentView(state) as View,
    isAlternateComehomeBrandingAndBrokerageDesignEnabled: getIsFeatureEnabled(
      'alternate_comehome_branding_and_brokerage_design'
    )(state),
    isTabletOrSmallerSize: getIsTabletOrSmallerSize(state),
    isInsideNativeApp: getIsInsideNativeApp(state),
    isShowingHUDLogo:
      [
        View.ALERTS,
        View.BROKERAGE_INFO,
        View.CONCIERGE_TEAM,
        View.CONFIRM_USER,
        View.HOMEOWNER,
        View.HOMEOWNER_CLAIMED_HOMES,
        View.HOMEOWNER_PROPERTY_DETAILS,
        View.HOMEPAGE,
        View.HOME_IMPROVEMENT,
        View.PROPERTY_DETAILS,
        View.SAVED_SEARCHES,
        View.WATCHLIST,
        ...ALL_SEARCH_VIEWS,
      ].indexOf(currentView) > -1,
    isShowingMLSInfo: isPDPView || isSearchView,
    supportUrl: getSupportUrl(state),
    isShowingBrokerLink: getIsShowingBrokerLinkInFooter(state),
    utmState: getUtmState(state),
    isLODirectEnabled,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reportStoreIosClick: () =>
    dispatch(reportEvent('click_store_ios', PARENT_EVENTS.CLICK_STORE)),
  reportStoreAndroidClick: () =>
    dispatch(reportEvent('click_store_android', PARENT_EVENTS.CLICK_STORE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
