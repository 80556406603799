import React, { Component, createRef } from 'react';

import LoadingSection from '@client/components/generic/LoadingSection';
import PropertyPageDesktopContentsCobranded from '@client/components/PropertyPageDesktopContents/PropertyPageDesktopContentsCobranded';
import PropertyPageMobileContentsCobranded from '@client/components/PropertyPageMobileContents/PropertyPageMobileContentsCobranded';
import PropertyPageTabletContentsCobranded from '@client/components/PropertyPageTabletContents/PropertyPageTabletContents';
import AccessiblePageTitleContainer from '@client/containers/accessible-page-title.container';
import PropertyPageTopBarContainer from '@client/containers/property-page-top-bar.container';
import theme from '@client/css-modules/PropertyPage.css';
import { View } from '@client/routes/constants';
import {
  eventType,
  ParentEventType,
} from '@client/store/actions/analytics.actions';
import { Status, STATUSES } from '@client/store/constants';
import {
  AnalyticsEventAddress,
  AnalyticsTopLevelItems,
} from '@client/store/types/analytics';
import { NormalizedProperty } from '@client/store/types/property';
import { clearAllBodyScrollLocks } from '@client/utils/body-scroll-lock';
import { getDataAttrEventNameForDOMEventTarget } from '@client/utils/dom.utils';

type Props = {
  /* Whether this component is being rendered server-side */
  activePropertyHasData: boolean;
  isAppMounted: boolean;
  isPDPDataInit: boolean;
  isPDPDataLoaded: boolean;
  failedToLoad: boolean;
  fetchInitData: (slug: string) => void;
  fetchFullData: (slug: string) => void;
  fetchMortgageCalculationDefaults: ({
    activePropertySlug,
    data,
    isFetchingDefaultTerms,
  }: {
    activePropertySlug: string;
    data: { homePrice: number | null };
    isFetchingDefaultTerms: boolean;
  }) => void;
  setLocalStoragePropertySeen: (slug: string) => void;
  isSmallSize: boolean;
  addressSlug: string;
  isActiveListing: boolean;
  shouldShowHomeownerAd: boolean;
  isTabletSize: boolean;
  isCanaryUIFeatureEnabled: boolean;
  isReferralServicesEnabled?: boolean;
  homePrice: number | null;
  mortgageCalculationDefaultsStatus: Status;
  isHidingAvmBreakdown?: boolean;
  property: NormalizedProperty | null;
  loanOfficerId: string | null;
  source?: View;
  sourceId?: string;
  handleReportEvent: (
    event: eventType,
    parentEvent: ParentEventType | null,
    address: AnalyticsEventAddress,
    topLevelItems?: AnalyticsTopLevelItems
  ) => void;
  reportPageview: (
    address: NormalizedProperty | null,
    loanOfficerId: string | null
  ) => void;
};

class PropertyPage extends Component<Props> {
  buttonRef: React.RefObject<HTMLButtonElement> = createRef();
  refocusTimeout: number | null = null;

  componentDidMount() {
    const {
      addressSlug,
      isPDPDataInit,
      fetchInitData,
      fetchFullData,
      fetchMortgageCalculationDefaults,
      homePrice,
      setLocalStoragePropertySeen,
      property,
      reportPageview,
      loanOfficerId,
    } = this.props;

    /* When coming from clicking a property card on the search page OR loading the PDP directly,
     * fetch init data.  When coming from the search page, the property will have data in the cache
     * for a quicker display of the PDP, but we still want to fetch complete data */
    if (isPDPDataInit) {
      fetchInitData(addressSlug);
    }
    /**
     * Currently HomePrice is required to fetch mortgage calculation defaults
     * Property details init data should be on state for homePrice to be available
     */
    if (addressSlug && homePrice) {
      fetchMortgageCalculationDefaults({
        activePropertySlug: addressSlug,
        data: { homePrice },
        isFetchingDefaultTerms: true,
      });
    }

    fetchFullData(addressSlug);
    setLocalStoragePropertySeen(addressSlug);
    /* In certain cases, we're seeing `enableBodyScroll` calls in `componentWillUnmount` methods of
     * other pages' components not effective clearing the locks.  Calling this here for safety since
     * this page depends on the page body scrolling */
    clearAllBodyScrollLocks();

    reportPageview(property, loanOfficerId);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      addressSlug,
      fetchInitData,
      fetchFullData,
      fetchMortgageCalculationDefaults,
      homePrice,
      loanOfficerId,
      mortgageCalculationDefaultsStatus,
      property,
      reportPageview,
    } = this.props;

    /* When loading a new PDP from an existing PDP via the address search, need to get full
     * details again */
    if (addressSlug !== prevProps.addressSlug && addressSlug) {
      reportPageview(property, loanOfficerId);
      fetchInitData(addressSlug);
      fetchFullData(addressSlug);
    }
    /**
     * Currently HomePrice is required to fetch mortgage calculation defaults
     * Property details init data should be on state for homePrice to be available
     * This is to account for the use cases where property details init data is not
     * on state on ComponentDidMount, hence the check to see if homePrice was
     * unavailable on previous props and available on current props
     */
    if (
      mortgageCalculationDefaultsStatus === STATUSES.INIT &&
      !prevProps.homePrice &&
      homePrice &&
      addressSlug
    ) {
      fetchMortgageCalculationDefaults({
        activePropertySlug: addressSlug,
        data: { homePrice },
        isFetchingDefaultTerms: true,
      });
    }
  }

  /**
   * Report any click event where a `data-event-name` is defined on a DOM node or parent DOM node,
   * adding context about the current property
   */
  handleDataAttrEventReporting = (
    e: React.MouseEvent | React.KeyboardEvent
  ) => {
    const { eventName, parentEventName, eventData } =
      getDataAttrEventNameForDOMEventTarget(e);
    const { property, handleReportEvent, source, sourceId } = this.props;

    const topLevelItems =
      source && sourceId ? { source, source_id: sourceId } : undefined;

    if (eventName && property) {
      const propertyAddress: AnalyticsEventAddress = {
        slug: property.slug,
        unit: property.unit,
        street: property.streetAddress,
        city: property.city,
        state: property.state,
        zip: property.zipcode,
        address_id: property.hcAddressId,
      };
      handleReportEvent(
        eventName as eventType,
        parentEventName as ParentEventType | null,
        {
          ...propertyAddress,
          ...eventData,
        },
        topLevelItems
      );
    }
  };

  render() {
    const {
      activePropertyHasData,
      isPDPDataLoaded,
      failedToLoad,
      isSmallSize,
      isAppMounted,
      isActiveListing,
      shouldShowHomeownerAd,
      isTabletSize,
      isCanaryUIFeatureEnabled,
      isReferralServicesEnabled,
      isHidingAvmBreakdown,
    } = this.props;

    return (
      !failedToLoad && (
        <div
          className={theme.PropertyDetailsPage}
          onClick={this.handleDataAttrEventReporting}
          onKeyDown={this.handleDataAttrEventReporting}
        >
          <LoadingSection
            isLoading={!activePropertyHasData}
            className={theme.LoadingSection}
          >
            <AccessiblePageTitleContainer />
            <PropertyPageTopBarContainer />
            {
              /* Since the server doesn't know the client screen size, we need to server-side render
               * the mobile, tablet, and desktop versions and show/hide the appropriate versions using CSS */
              (isSmallSize || !isAppMounted) && (
                <div className={theme.SmallScreenContents}>
                  <PropertyPageMobileContentsCobranded
                    isActiveListing={isActiveListing}
                    shouldShowHomeownerAd={shouldShowHomeownerAd}
                    isPDPDataLoaded={isPDPDataLoaded}
                    isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
                    isReferralServicesEnabled={isReferralServicesEnabled}
                    isHidingAvmBreakdown={isHidingAvmBreakdown}
                  />
                </div>
              )
            }
            {((!isSmallSize && !isTabletSize) || !isAppMounted) && (
              <div className={theme.LargeScreenContents}>
                <PropertyPageDesktopContentsCobranded
                  shouldShowHomeownerAd={shouldShowHomeownerAd}
                  isActiveListing={isActiveListing}
                  isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
                  hasNearbyListings={false}
                  isHidingAvmBreakdown={isHidingAvmBreakdown}
                />
              </div>
            )}
            {((!isSmallSize && isTabletSize) || !isAppMounted) && (
              <div className={theme.TabletScreenContents}>
                <PropertyPageTabletContentsCobranded
                  shouldShowHomeownerAd={shouldShowHomeownerAd}
                  isActiveListing={isActiveListing}
                  isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
                  isReferralServicesEnabled={isReferralServicesEnabled}
                  isHidingAvmBreakdown={isHidingAvmBreakdown}
                />
              </div>
            )}
          </LoadingSection>
        </div>
      )
    );
  }
}

export default PropertyPage;
